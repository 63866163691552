import {UserModel} from '../../user/models/user.model';
import {PlatformEnum} from "../../shared/enums/platform.enum";
import {Capacitor} from "@capacitor/core";
import {generateUuid} from "../../shared/utils/service-utils";

export class DeviceDto {
  platform: PlatformEnum; // The device platform (lowercase).
  identifier?: string;

  constructor() {
    this.platform = PlatformEnum[Capacitor.getPlatform()];
    this.identifier = generateUuid();
  }
}

export interface LoginDto {
  username: string;
  password: string;
  device?: DeviceDto;
}

export interface TokenModel {
  jwt: string;
  expirationDate: number;
}

export interface LoginResponseDto {
  token: TokenModel;
  refreshToken: TokenModel;
  user: UserModel;
}

export interface RefreshTokensDto {
  user: UserModel;
  refreshToken: TokenModel;
  device?: DeviceDto;
}
