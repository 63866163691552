import {getLabelFromEnum} from "../../shared/utils/service-utils";

export enum EntityDataState {
  SHARE_REMOVED = 'share-removed',
  REMOVED = 'removed',
}

export enum EntityDataStateLabel {
  SHARE_REMOVED = 'Oggetto rimosso dalla chat',
  REMOVED = 'Oggetto eliminato dal proprietario',
}

export enum TextState {}

export function getLabel(relatedEntity: EntityDataState | string) {
  return getLabelFromEnum(EntityDataState, EntityDataStateLabel, relatedEntity);
}
