import {Injectable} from '@angular/core';
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {PlatformEnum} from "../enums/platform.enum";
import {Capacitor} from "@capacitor/core";
import {StatusBar, StatusBarInfo, Style} from "@capacitor/status-bar";

@Injectable({
  providedIn: 'root'
})
export class CheckUtilsService {

  constructor(
    private http: HttpClient,
  ) { }

  async makeStatusBarOverlayFalse(): Promise<StatusBarInfo | null> {
    const platform = PlatformEnum[Capacitor.getPlatform()];
    if(platform == PlatformEnum.ios) {
      await StatusBar.setOverlaysWebView({overlay: false});
      await StatusBar.setStyle({ style: Style.Light});
      return StatusBar.getInfo();
    }
    return null;
  }
  async getVersionBackend() {
    const url = `${environment.BASE_PATH}/version`;
    return (await this.http.get<any>(`${url}`).toPromise())?.version;
  }

  todayWithTimezone() {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    return dayjs().tz('Europe/Rome');
  }

  goBack() {
    window.history.back();
  }

  setTimeFromDate (date: string | Date) : string | null {
    if(!date) return null;
    const dateDay = dayjs(date);
    const hour = dateDay.get('hour');
    const min = dateDay.get('minutes');
    let hourString = hour.toString();
    let minString = min.toString();
    if(min < 10) minString = `0${minString}`;
    if(hour < 10) hourString = `0${hourString}`;
    return `${hourString}:${minString}`;
  }

  dateToItalyLocalDateString(stringDate: Date | string) {
    return new Date(stringDate).toLocaleDateString('en-GB');
  }
}
