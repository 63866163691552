import { Pipe, PipeTransform } from "@angular/core";
import { isObservable } from "rxjs";
import { map } from "rxjs/operators";

// @Pipe({ name: "secondsToHHmmss" })
// export class SecondsToHHmmssPipe implements PipeTransform {
//   transform(seconds: number) {
//     return seconds / 3600;
//   }
// }
//
// @Pipe({ name: "hints" })
// export class HintsPipe implements PipeTransform {
//   transform(entity): string {
//     let hint = "";
//     let first = true;
//     if (entity.documentHints) {
//       for (const dh of entity.documentHints) {
//         if (!first) {
//           hint += ", ";
//         }
//         let msg = "";
//         switch (dh.hint) {
//           case "missing":
//             msg = "mancante";
//             break;
//           case "expired":
//             msg = "scaduto";
//             break;
//           default:
//             msg = dh.hint;
//         }
//         hint += `${dh.presetKey} - ${dh.docName}  ${msg}`;
//         first = false;
//       }
//     }
//     return hint;
//   }
// }
//
// @Pipe({ name: "timePercent" })
// export class TimePercentPipe implements PipeTransform {
//   transform(entity: WbsModel): number {
//     if (entity.startDate && entity.endDate) {
//       const start = new Date(entity.startDate).getTime();
//       const end = new Date(entity.endDate).getTime();
//       const today = new Date().getTime();
//       const value = 100 - Math.ceil(((end - today) / (end - start)) * 100);
//       return value > 100 ? 100 : value < 0 ? 0 : value;
//     }
//     return null;
//   }
// }

@Pipe({ name: "filter" })
export class SearchPipe implements PipeTransform {
  transform(list: any, value: string, attributes?: string[]): any {
    if (isObservable(list)) {
      return list.pipe(
        map((array: any[]) => this.filterList(array, value, attributes))
      );
    }

    if (!value.length || !Array.isArray(list)) {
      return list;
    }
    return this.filterList(list, value, attributes);
  }

  filterList(list: any[], value: string, attributes: string[]) {
    return list.filter((el) => {
      if (!attributes) {
        return JSON.stringify(el).toLowerCase().includes(value.toLowerCase());
      } else {
        let res = false;
        for (const attribute of attributes) {
          const stringhed = String(el[attribute]);
          if (stringhed) {
            if (stringhed.toLowerCase().includes(value.toLowerCase())) {
              res = true;
            }
          }
        }
        return res;
      }
    });
  }
}
//
// @Pipe({ name: "filterDateLt" })
// export class SearchDateLtPipePipe implements PipeTransform {
//   transform(list: any, value: Date, attributes: string[]): any {
//     if (isObservable(list)) {
//       return list.pipe(
//         map((array: any[]) => this.filterList(array, value, attributes))
//       );
//     }
//
//     if (!value || !Array.isArray(list)) {
//       return list;
//     }
//     return this.filterList(list, value, attributes);
//   }
//
//   filterList(list: any[], value: Date, attributes: string[]) {
//     return list.filter((el) => {
//       let res = false;
//       for (const attribute of attributes) {
//         const dateToCheck = new Date(el[attribute]);
//         if (dateToCheck) {
//           if (!value || dateToCheck < value) {
//             res = true;
//           }
//         }
//       }
//       return res;
//     });
//   }
// }
//
// @Pipe({ name: "paginate" })
// export class PaginatePipe implements PipeTransform {
//   transform(list: any, pageSize: number, pageIndex: number): any {
//     if (isObservable(list)) {
//       return list.pipe(
//         map((array: any[]) => this.paginateList(array, pageSize, pageIndex))
//       );
//     } else {
//       return this.paginateList(list, pageSize, pageIndex);
//     }
//   }
//
//   paginateList(list: any[], pageSize: number, pageIndex: number) {
//     return list.slice(pageIndex * pageSize, (+pageIndex + 1) * pageSize);
//   }
// }
