import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { Observable } from 'rxjs';
import { canAcceptTerms } from '../../selectors/auth.selector';
import { map, take } from 'rxjs/operators';

@Injectable()
export class CanActivatePrivacyGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(canAcceptTerms).pipe(
      take(1),
      map(can => {
        if (!can) {
          const callbackUrl = location.href;
          this.router.navigate(['/login'], { queryParams: { callbackUrl } });
        }
        return true;
      })
    );
  }
}
