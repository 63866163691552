import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/components/login/login.component';
import { CanLoadRoleGuard } from './auth/guards/roles-guard/can-load-role-guard.service';
import { CanActivateRoleGuard } from './auth/guards/roles-guard/can-activate-role-guard.service';
import { AppPermissions } from './user/enum/app-permissions.enum';
import { CanActivateGuard } from './auth/guards/auth-guard/can-activate.guard';
import { CanLoadGuard } from './auth/guards/auth-guard/can-load.guard';
import { PrivacyComponent } from './auth/components/privacy/privacy.component';
import { CanActivatePrivacyGuard } from './auth/guards/privacy-guard/can-activate-privacy.guard';
import {CheckRoutes, CommonRoutes} from "./shared/enums/routes.enum";
import {RequestPasswordComponent} from "./auth/components/request-password/request-password.component";
import {ChangeUsernamePasswordComponent} from "./auth/components/change-username-password/change-username-password.component";
import {UserConfirmComponent} from "./auth/components/user-confirm/user-confirm.component";
import {ResetPasswordComponent} from "./auth/components/reset-password/reset-password.component";
import {
  ArchiveDownloaderComponent
} from "./archive/components/archive-downloader/archive-downloader.component";
import {
  CanActivateDownloadArchiveGuard
} from "./archive/guards/download-archive-guard/download-archive.guard";
import {UserActivationComponent} from "./auth/components/user-activation/user-activation.component";
import {ModuleNameEnum} from "./auth/enums/module-name.enum";

const roles = [AppPermissions.SUPERADMIN, AppPermissions.ROLE_BOARD_ADMIN, AppPermissions.SUPPORT, AppPermissions.GUEST];

const routes: Routes = [
  {
    path: `${CheckRoutes.ACTOR_EMPLOYEE}/${CheckRoutes.ACTOR_EMPLOYEE_STAFF_CARD}/:id`,
    redirectTo: `${CheckRoutes.USER}/${CheckRoutes.ACTOR_EMPLOYEE}/${CheckRoutes.ACTOR_EMPLOYEE_STAFF_CARD}/:id`,
    pathMatch: "prefix"
  },
  {
    path: `${CommonRoutes.LOGIN}`,
    component: LoginComponent,
  },
  {
    path: `${CommonRoutes.NEW_USER}`,
    component: UserActivationComponent,
  },
  {
    path: `${CommonRoutes.REQUEST_PASSWORD}`,
    component: RequestPasswordComponent,
  },
  {
    path: `${CommonRoutes.RESET_PASSWORD}/:confirmationToken`,
    component: ResetPasswordComponent,
  },
  {
    path: `${CommonRoutes.CHANGE_USERNAME_PASSWORD}/:username/:confirmationToken`,
    component: ChangeUsernamePasswordComponent,
  },
  {
    path: `${CommonRoutes.DOWNLOAD_ARCHIVE}/:plannedOperationId`,
    component: ArchiveDownloaderComponent,
    canActivate: [CanActivateDownloadArchiveGuard]
  },
  {
    path: `${CommonRoutes.USER_CONFIRM}/:confirmationToken`,
    component: UserConfirmComponent,
  },
  {
    path: `${CommonRoutes.PRIVACY}`,
    component: PrivacyComponent,
    canActivate: [CanActivatePrivacyGuard],
  },
  {
    path: `${CheckRoutes.USER}`,
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canLoad: [CanLoadGuard],
    data: {  module: ModuleNameEnum.dashboard },
  },
  {
    path: `${CommonRoutes.PUBLIC}`,
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
  },
  {
    path: `${CheckRoutes.BOARD}`,
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canLoad: [CanLoadGuard],
    data: { roles: roles, module: 'home' },
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  providers: [
    CanLoadRoleGuard,
    CanActivateRoleGuard,
    CanActivateGuard,
    CanLoadGuard,
    CanActivatePrivacyGuard
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
