import { DatePipe } from '@angular/common';
import { environment } from '../../../environments/environment';
import { v4 as uuidv4 } from 'uuid';

export function getStringDateOrEmptyString(data: Date | null | undefined, format: string): string {
  const datePipe = new DatePipe('en-US');
  const tempName = datePipe.transform(data, format);
  return tempName ? tempName : '';
}

/**
 * Returns a date in format 'dd-mm-yyyy' or an empty string if the date is null
 * @param data
 */
export function getDateStringOrEmptyString(data: Date | null | undefined) {
  return getStringDateOrEmptyString(data, environment.DATE_FORMAT);
}

// Helper
const StringIsNotNumber = (value: any) => isNaN(Number(value));

// Turn enum into array
export function StringEnumToArray(enumeration: any) {
  return Object.keys(enumeration)
    .filter(StringIsNotNumber)
    .map(key => enumeration[key]);
}

export function get30DaysAgoDate(): Date{
  const todayDate = new Date();
  return new Date(new Date().setDate(todayDate.getDate() - 30));
}

export function getLabelFromEnum (enumkeys: any, labels: any, enumkey: any) {
  const key = Object.keys(enumkeys)[Object.values(enumkeys).indexOf(enumkey.toLowerCase())];
  return labels[key];
}

export function generateUuid() : string {
  return uuidv4();
}

export async function getBase64ImageFromURL(url: string) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext("2d");
      ctx!.drawImage(img, 0, 0);

      const dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = url;
  });
}
