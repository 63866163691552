import {ChatMessageActionType, ChatMessageActionTypeLabel} from "../enums/chat-message-type.enum";
import {TagModel} from "../../shared/tags/models/tag.model";
import {EntityDataState, TextState} from "../enums/message-state.enum";

export enum SharableEntity {
  DOCUMENT = "document",
  EQUIPMENT = "equipment",
  CCO = "cco",
  ACTIVITY = "activity"
}

export function GetSharableEntities() : SharableEntity[] {
  return Object.values(SharableEntity);
}

export class ContentMessageModel {
  text?: string;
  entityName?: SharableEntity;
  entityId?: number;
  entityData?: any; // DocumentModel | CcoModel | EquipmentModel
}

export class ContentStateModel { // di default lo stato non c'è, se il mess viene modificato o cancellato allora viene inserito il campo state
  entityData?: EntityDataState;
  text?: TextState; // in caso ci chiedano di modificare o cancellare il testo
}

export class SharedMessageModel {
  id?: string;
  createdAt: {
    seconds: number;
    nanoseconds: number;
  }
  createdBy: number; // id utente creatore del messaggio
  createdByActor: number; // id attore
  updatedAt?: {
    seconds: number;
    nanoseconds: number;
  }
  constructionId: number; // cantiere in cui il msg è stato condiviso, serve per wall
  chatGroupId: string; // gruppo in cui il msg è stato condiviso, serve per wall
  chatGroupName: string;
  createdByUID: string;
  createdByUsername: string;
  createdByUsernameComplete: any;
  createdByActorDenomination: string;
  type: ChatMessageActionType;
  typeLabel: ChatMessageActionTypeLabel | string;
  content: ContentMessageModel;
  contentState: ContentStateModel;
  tags?: TagModel[];
  tagData?: string[]; // elenco di colori, labels, icons di tutti i tag scelti per il messaggio, serve per poter filtrare per colore e/o icona e/o label
  // si puà avere solo un array-contains per query
}
