export enum NotificationType {
  TASK_COMPLETED = 'task-completed',
  TASK_DELETED = 'task-deleted',
  TASK_ASSIGNED= 'task-assigned',
  TASK_REASSIGNED = 'task-reassigned',
  CHAT_CREATED = 'chat-created', // non si riesce ad usare la cloud function chat-group-change-notification per questo evento
}

export enum NotificationEntityData {
  CHAT = "chat",
  ACTIVITY = "activity"
}
