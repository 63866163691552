import {Injectable} from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {NotificationEntityData, NotificationType} from "../../enums/notification.type.enum";
import {BaseUserService} from "../base-user/base-user.service";
import {ActorService} from "../../../actor/services/actor/actor.service";
import {ActorModel} from "../../../actor/models/actor.model";

export type NotificationDoc = {
  type: NotificationType,
  link: string,
  queryParams?: string,
  recipientUsers: string[],
  entityId?: number,
  entityName?: NotificationEntityData | any;
  entityData?: any,
  createdAt: Date,
  createdBy: number,
  createdByUsername: string,
  createdByUID: string,
  createdByActor: number,
  createdByActorDenomination: string,
  message?: string,
}

export type DataNotification = {
  type: NotificationType,
  entityId?: number,
  entityName?: string;
  entityData?: any,
}

export type MessageNotification = {
  link: string,
  queryParams?: string,
  message?: string,
  recipientUsers: string[],
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseNotificationService {

  constructor(
    private afs: AngularFirestore,
    private actorService: ActorService,
    private baseUserService: BaseUserService
  ) { }

  async sendFirebaseNotification(dataNotification: DataNotification, messageNotification: MessageNotification): Promise<any> {
    const docContent: NotificationDoc = await this.buildFirebaseNotification(dataNotification, messageNotification);
    console.log('Sending notification to firebase...', docContent);
    return await this.afs.collection('notifications').add(docContent);
  }

  private async buildFirebaseNotification(dataNotification: DataNotification, messageNotification: MessageNotification) {
    const loggedUser = await this.baseUserService.loggedUser();
    const loggedActor: ActorModel = await this.actorService.loggedActor()

    const docContent: NotificationDoc = {
      type: dataNotification.type,
      entityId: dataNotification.entityId,
      entityName: dataNotification.entityName,
      entityData: {...dataNotification.entityData},

      link: messageNotification.link,
      recipientUsers: messageNotification.recipientUsers,
      message: messageNotification.message,
      queryParams: messageNotification.queryParams,

      createdAt: new Date(),
      createdBy: loggedUser?.id,
      createdByUsername: loggedUser?.username,
      createdByUID: loggedUser?.chatUID,
      createdByActor: loggedActor?.id,
      createdByActorDenomination: loggedActor?.denomination,
    }

    return docContent;
  }
}
