import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RolesGuardService } from '../../services/roles-guard/roles-guard.service';

@Injectable()
export class CanActivateRoleGuard implements CanActivate {
  constructor(private rolesGuardService: RolesGuardService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.rolesGuardService.canUserGo(route);
  }
}
