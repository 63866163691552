import {SharableEntity} from "../models/shared-entity.model";

export enum FilterableMessageType {
  ALL = 'ALL',
  TEXT = 'TEXT',
  SURVEY = 'SURVEY',
  JOURNAL = 'JOURNAL',
  PHOTO_GALLERY = 'PHOTO_GALLERY',
  EQUIPMENT = 'EQUIPMENT',
  DOCUMENT = 'DOCUMENT',
  ACTIVITY = 'ACTIVITY'
}

export enum FilterableMessageTypeLabel {
  ALL = 'Globale',
  TEXT = 'Messaggi',
  SURVEY = 'Verbali',
  JOURNAL = 'Diari',
  PHOTO_GALLERY = 'Fascicoli fotografici',
  EQUIPMENT = 'Mezzi',
  DOCUMENT = 'Documenti',
  ACTIVITY = 'Attività',
}

export function GetSharableEntityFromMessageType(type: FilterableMessageType | string) {
  switch (type){
    case FilterableMessageType.DOCUMENT:
      return SharableEntity.DOCUMENT;
    case FilterableMessageType.EQUIPMENT:
      return SharableEntity.EQUIPMENT;
    case FilterableMessageType.SURVEY:
      return SharableEntity.CCO;
    case FilterableMessageType.JOURNAL:
      return SharableEntity.CCO;
    case FilterableMessageType.PHOTO_GALLERY:
      return SharableEntity.CCO;
    case FilterableMessageType.ACTIVITY:
      return SharableEntity.ACTIVITY;
    default:
      return null;
  }
}

