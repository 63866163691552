import { Component, OnInit } from '@angular/core';
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {UserService} from "../../../user/services/user/user.service";
import {ActivatedRoute} from "@angular/router";
import {MessageModel} from "../../../shared/models/message.model";
import {CommonRoutes} from "../../../shared/enums/routes.enum";

@Component({
  selector: 'app-change-username-password',
  templateUrl: './change-username-password.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class ChangeUsernamePasswordComponent implements OnInit {

  PASSWORD_REGEX = "^(?=.*\\d)[a-zA-Z0-9]{7,}$";

  oldUsername: string;
  confirmationToken: string;

  CommonRoutes = CommonRoutes;

  form = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new FormControl('',[Validators.required, Validators.pattern(this.PASSWORD_REGEX)]),
  });
  hideForm = false;
  messages: MessageModel[] = [];

  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
  ) { }

  async ngOnInit(): Promise<void> {
    this.oldUsername = this.activatedRoute.snapshot.paramMap.get('username');
    this.confirmationToken = this.activatedRoute.snapshot.paramMap.get('confirmationToken');
    const messageModel = await this.userService.checkUserForConfirmation(this.oldUsername, this.confirmationToken);
    this.messages.push(messageModel);
    if(messageModel.error) {
      this.hideForm = true;
      this.form.disable();
    }
  }

  async change() {
    this.messages = [];
    this.form.markAllAsTouched();
    const username = this.form.get('username').value;

    if(this.oldUsername == username) {
      this.messages.push(new MessageModel(`Lo username inserito è uguale al precedente: inserire un'email non pec`, true));
    }

    if(this.messages.length > 0) return;

    if(this.form.valid) {
      const password = this.form.get('password').value;
      const messageModel = await this.userService.changeUsernamePassword(
        {
              username,
              password,
              oldUsername: this.oldUsername,
              confirmationToken: this.confirmationToken
        })
      this.messages.push(messageModel);
      if(!messageModel.error) {
        this.hideForm = true;
      }
    }
  }

  goBack() {
    window.history.back();
  }

}
