import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  loginSuccess,
  login,
  loginError,
  logout,
  logoutSuccess,
  storeTokens,
  acceptTerms,
  acceptTermsError,
  acceptTermsSuccess,
  refreshAuthDataSuccess,
  refreshAuthData,
  refreshAuthDataError,
  impersonate,
  impersonateSuccess,
  recoverChatData, showError, firebaseSignIn
} from '../actions/auth.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginResponseDto } from '../models/login.model';
import { AuthService } from '../services/auth/auth.service';
import { AppPermissions } from '../../user/enum/app-permissions.enum';
import { Store } from '@ngrx/store';
import {AppState, clearAuthStorageData} from '../../reducers';
import { PrivacyService } from '../services/privacy/privacy.service';
import { UserModel } from '../../user/models/user.model';
import {CheckRoutes, CommonRoutes} from "../../shared/enums/routes.enum";
import {loggedActorLoad} from "../../actor/actions/actor.actions";
import {UserService} from "../../user/services/user/user.service";
import {UtilsService} from "../../shared/services/utils/utils.service";
import {ChatGroupService} from "../../construction-chat/services/chat-group/chat-group.service";
import {ChatService} from "../../construction-chat/services/chat/chat.service";
import {isBaseUser, isSupportUser} from "../../shared/services/base-user/base-user.service";

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private privacyService: PrivacyService,
    private userService: UserService,
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private chatGroupService: ChatGroupService,
    private chatService: ChatService,
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      exhaustMap(action =>
        this.authService.login(action.username, action.password).pipe(
          map((response: LoginResponseDto) => loginSuccess({ token: response.token, user: response.user, refreshToken: response.refreshToken })),
          catchError(error => {
            return of(loginError({ error: error?.error?.message ? error?.error?.message : 'Errore del server' }));
          })
        )
      )
    )
  );

  loggedIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccess),
        tap((response: LoginResponseDto) => {
          localStorage.setItem(`isRefreshing`, `false`);
          this.store.dispatch(storeTokens(response));

          if(!isSupportUser(response.user.rolesJson)) {
            if(response.user.chatToken) {
              this.store.dispatch(firebaseSignIn({chatToken: response.user.chatToken, userChatUID: response.user.chatUID, email: response.user.email}));
            } else {
              this.store.dispatch(recoverChatData(response.user));
            }
          }

          if (response.user.rolesJson?.includes(AppPermissions.SUPERADMIN) || !!response.user.constructionFundTermsAcceptedDate || !!response.user.userTermsAcceptedDate) {
            if (response.user.rolesJson?.includes(AppPermissions.ROLE_BOARD_ADMIN) || response.user.rolesJson?.includes(AppPermissions.SUPERADMIN) || response.user.rolesJson?.includes(AppPermissions.SUPPORT )|| response.user.rolesJson?.includes(AppPermissions.GUEST)) {
              return this.router.navigate([CheckRoutes.HOME]);
            } else {
              this.store.dispatch(loggedActorLoad({actorId: response.user.baseActorId}));
              const callbackUrl = this.route.snapshot.queryParams.callbackUrl;
              console.log(callbackUrl);
              if (callbackUrl) {
                location.href = this.route.snapshot.queryParams.callbackUrl;
              } else if (isBaseUser(response.user) || response.user.rolesJson?.includes(AppPermissions.CAN_SEE_DASHBOARDS)) {
                return this.router.navigate([CheckRoutes.USER]);
              } else {
                return this.router.navigate([CheckRoutes.USER, CheckRoutes.CONSTRUCTION]);
              }
            }
          } else {
            return this.router.navigate([CommonRoutes.PRIVACY]);
          }
        })
      ),
    { dispatch: false }
  );

  recoverChatData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recoverChatData),
      exhaustMap(action =>
        this.chatGroupService.getChatInfoObs().pipe(
          map((response) =>
            firebaseSignIn({chatToken: response.chatToken, userChatUID: response.userChatUID, email: action.email})
          ),
          catchError(error => {
            return of(showError({ error: 'Errore durante il recupero dei dati chat' }));
          })
        )
      )
    )
  );

  firebaseSigningIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(firebaseSignIn),
        tap(async (data: {chatToken: string, userChatUID: string, email: string}) => {
          await this.chatService.signInWithEmailAndPassword(data.email, data.chatToken);

          const userString = localStorage.getItem('user');
          try{
            const user = JSON.parse(userString);
            user.chatToken = data.chatToken;
            user.chatUID = data.userChatUID;
            localStorage.setItem('user', JSON.stringify(user));
          } catch (exc) {
            this.store.dispatch(showError({error: 'Impossibile utilizzare chat'}));
          }
        })
      ),
    { dispatch: false }
  );

  refreshAuthData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshAuthData),
      exhaustMap(action =>
        this.authService.refreshAuthData().pipe(
          map((response: LoginResponseDto) => refreshAuthDataSuccess({ token: response.token, user: response.user, refreshToken: response.refreshToken })),
          catchError(error => {
            return of(refreshAuthDataError({ error: error?.error?.message ? error?.error?.message : 'Errore del refresh' }));
          })
        )
      )
    )
  );

  refreshedAuthData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshAuthDataSuccess),
        tap(async (response: LoginResponseDto) => {
          this.store.dispatch(storeTokens(response));
          await this.chatService.signInWithEmailAndPassword(response.user.email, response.user.chatToken);
          }
        )
      ),
    { dispatch: false }
  );

  storeTokens$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(storeTokens),
        tap(action => {
          localStorage.setItem('auth', JSON.stringify(action.token));
          localStorage.setItem('refresh', JSON.stringify(action.refreshToken));
          localStorage.setItem('user', JSON.stringify(action.user));
        })
      ),
    { dispatch: false }
  );

  acceptTerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptTerms),
      exhaustMap(action =>
        this.privacyService.acceptTerms().pipe(
          map((response: UserModel) => acceptTermsSuccess(response)),
          catchError(error => {
            return of(acceptTermsError());
          })
        )
      )
    )
  );

  acceptTermsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(acceptTermsSuccess),
        tap((response: UserModel) => {
          localStorage.setItem('user', JSON.stringify(response));
          if (response.rolesJson?.includes(AppPermissions.ROLE_BOARD_ADMIN) || response.rolesJson?.includes(AppPermissions.SUPPORT) || response.rolesJson?.includes(AppPermissions.SUPERADMIN) || response.rolesJson?.includes(AppPermissions.GUEST)) {
            return this.router.navigate(['home']);
          } else {
            return this.router.navigate([CheckRoutes.USER]);
          }
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logout),
      exhaustMap(() => this.authService.logout().pipe(map(() => logoutSuccess())))
    )
  );

  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutSuccess),
        tap(async () => {
          await this.router.navigate(['login']);
          clearAuthStorageData();
          location.reload();
        })
      ),
    { dispatch: false }
  );

  impersonate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(impersonate),
      exhaustMap(action =>
        this.userService.impersonate(action.userToImpersonateId).pipe(
          map((response: LoginResponseDto) => impersonateSuccess({ token: response.token, user: response.user, refreshToken: response.refreshToken })),
          catchError(error => {
            return of(showError({ error: error ? error : 'Errore del server' }));
          })
        )
      )
    )
  );

  impersonateSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(impersonateSuccess),
        tap((response: LoginResponseDto) => {
          this.store.dispatch(loginSuccess(response));
        })
      ),
    { dispatch: false }
  );

  showError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showError),
        tap((message: {error: string}) => {
          this.utilsService.openSnackbar(message.error, 2000, `red-snackbar`);
        })
      ),
    { dispatch: false }
  );
}
