export enum CcoType {
  PHOTO_GALLERY = 'photo_gallery',
  JOURNAL = 'journal',
  SURVEY = 'survey'
}

export enum CcoTypeLabel {
  photo_gallery = 'Fascicolo fotografico',
  journal = 'Diario',
  survey = 'Sopralluogo'
}
