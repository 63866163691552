import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromActor from '../reducers/actor.reducers';

export const selectActorState = createFeatureSelector<fromActor.State>(fromActor.actorFeatureKey);

export const getState = createSelector(selectActorState, state => state);

export const getActor = createSelector(selectActorState, state => state?.actorModel);

export const getFound = createSelector(selectActorState, state => state?.found);

export const getErrorMessages = createSelector(selectActorState, state => state?.errorMessages);

export const getSuccessMessages = createSelector(selectActorState, state => state?.successMessages);
