import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { UserModel } from '../../models/user.model';
import {LoginDto, } from "../../../auth/models/login.model";
import {MessageModel} from "../../../shared/models/message.model";
import {DeviceService} from "../../../shared/services/device/device.service";

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private http: HttpClient,
    private deviceService: DeviceService,
  ) {}

  impersonate(userId: number) {
    const deviceDto = this.deviceService.getDeviceDtoFromLS();
    return this.http.post(`${environment.BASE_PATH}/user/${userId}/impersonate`, {device: deviceDto});
  }

  async getOne(id: number, query?: string) {
    return this.http.get<UserModel>(`${environment.BASE_PATH}/user/${id}?${query || ''}`).toPromise();
  }

  async getMany(query?: string) {
    return this.http.get<UserModel[]>(`${environment.BASE_PATH}/user?${query || ''}`).toPromise();
  }

  async deleteOne(id: number) {
    return this.http.delete(`${environment.BASE_PATH}/user/${id}`).toPromise();
  }

  replaceOne(user: UserModel) {
    return this.http.put(`${environment.BASE_PATH}/user/${user.id}`, user);
  }

  activateFromActor(actorId: number) {
    return this.http.get(`${environment.BASE_PATH}/user/activate-from-actor/${actorId}`);
  }

  async secondaryEnabledUsers() {
    return this.http.get<UserModel[]>(`${environment.BASE_PATH}/user/secondary/enabled`).toPromise();
  }

  createOne(user: UserModel) {
    return this.http.post(`${environment.BASE_PATH}/user`, user);
  }

  async createAPIKey(user: UserModel) : Promise<LoginDto> {
    return await this.http.post<LoginDto>(`${environment.BASE_PATH}/user/create-api-key`, user).toPromise();
  }

  async createImpersonification() {
    return await this.http.post(`${environment.BASE_PATH}/impersonification`, {}).toPromise();
  }

  async checkUserForConfirmation(username: string, confirmationToken: string) {
    return await this.http.get<MessageModel>(`${environment.BASE_PATH}/user-public/username/${username}/token/${confirmationToken}`).toPromise();
  }

  async changeUsernamePassword(dto: {username: string, oldUsername: string, password: string, confirmationToken: string}) {
    return await this.http.post<MessageModel>(`${environment.BASE_PATH}/user-public/change-username-password`, dto).toPromise();
  }

  async confirm(confirmationToken: string) {
    return await this.http.get<MessageModel>(`${environment.BASE_PATH}/user-public/token/${confirmationToken}/confirm`).toPromise();
  }

  async requestPassword(username: string) {
    return await this.http.post<MessageModel>(`${environment.BASE_PATH}/user-public/request-password`, {username}).toPromise();
  }

  async checkUserForResetPassword(confirmationToken: string) {
    return await this.http.post<MessageModel>(`${environment.BASE_PATH}/user-public/check-by-confirmation-token`, {confirmationToken}).toPromise();
  }

  async resetPassword(password: string, confirmationToken: string) {
    return await this.http.post<MessageModel>(`${environment.BASE_PATH}/user-public/reset-password`, {password, confirmationToken}).toPromise();
  }

  async changeUsername(dto: {newUsername: string, id: number}) {
    return await this.http.post<MessageModel>(`${environment.BASE_PATH}/user/change-username`, dto).toPromise();
  }

  async getUserAssociatedActorId(userId: number) {
    return await this.http.get(`${environment.BASE_PATH}/user/${userId}/associated-actor`).toPromise();
  }

  async userActivationRequest(fiscalCode: string, captchaResponse: string) : Promise<MessageModel> {
    return await this.http.get<MessageModel>(`${environment.BASE_PATH}/user/activation/${fiscalCode}/${captchaResponse}`).toPromise();
  }

  async isUserEnabled(id: number) {
    return await this.http.get<boolean>(`${environment.BASE_PATH}/user/${id}/is-enabled`).toPromise();
  }
}
