import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {CommonRoutes} from "../../../shared/enums/routes.enum";

@Component({
  selector: 'app-request-password-link',
  templateUrl: './request-password-link.component.html',
  styleUrls: ['./request-password-link.component.scss']
})
export class RequestPasswordLinkComponent implements OnInit {

  pwaEnabled = false;
  CommonRoutes = CommonRoutes;

  constructor() { }

  ngOnInit(): void {
    this.pwaEnabled = environment.PWA_ENABLED;
  }

}
