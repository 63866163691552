import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ActorEmployeeModel } from '../../../actor-employee/models/actor-employee.model';
import { RequestQueryBuilder } from '@nestjsx/crud-request';

@Injectable({
  providedIn: 'root',
})
export class ActorEmployeeService {
  constructor(private http: HttpClient) {}

  async countPrevinetEmployees(actorId: number | undefined) {
    if (actorId) {
      const actorEmployees = await this.getActorEmployees(actorId);
      return actorEmployees.data.filter((el: ActorEmployeeModel) => el.source == 'cfp' || el.source == 'previnet').length;
    }
    return 0;
  }

  async getActorEmployees(actorId: number) {
    const qb = RequestQueryBuilder.create({ search: { actorId: actorId } });
    return await this.http.get<any>(`${environment.BASE_PATH}/actor-employee?${qb.query()}`).toPromise();
  }

  getSourceText(source: string, sourceDetail: string, sourceLastCompetence: string, created: Date | undefined) {
    if (source == 'manual') {
      return 'Inserito manualmente il ' + dayjs(created).format('DD/MM/YYYY');
    }
    return 'Cassa Edile ' + this.getBoard(sourceDetail) + ', aggiornato al ' + this.formatLastCompetence(sourceLastCompetence);
  }

  getBoard(sourceDetail: string): string {
    if (sourceDetail.length >= 4) {
      return sourceDetail.slice(0, 2);
    }
    return sourceDetail;
  }

  formatLastCompetence(sourceLastCompetence: string): string {
    if (sourceLastCompetence && sourceLastCompetence.length >= 6) {
      return sourceLastCompetence.slice(4, 6) + '/' + sourceLastCompetence.slice(0, 4);
    }
    return '';
  }
}
