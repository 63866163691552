import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {getMe, getRoles} from '../../selectors/auth.selector';
import { map } from 'rxjs/operators';
import { AppState } from '../../../reducers';
import {ActivatedRouteSnapshot, Route, Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppPermissions } from 'src/app/user/enum/app-permissions.enum';
import {isBaseUser, isFinalUser} from "../../../shared/services/base-user/base-user.service";
import {ModuleNameEnum} from "../../enums/module-name.enum";
import {CheckRoutes} from "../../../shared/enums/routes.enum";

@Injectable({
  providedIn: 'root',
})
export class RolesGuardService {
  constructor(private store: Store<AppState>,
              private router: Router) {}

  canUserGo(route: Route | ActivatedRouteSnapshot) {
    // Si passa prima da Guard CanLoad/CanActivate e, se ritornano true, poi dall'interceptor
    // Quindi se il token jwt scade, Can* devono permettere di arrivare all'interceptor che chiamerà refresh-tokens (401)
    // Se scade il refresh token, invece, viene fatto un logout con redirect al login sempre partendo dall'interceptor (403)
    return this.store.pipe(
      select(getRoles),
      map(roles => {
        const requiredRoles = route.data?.roles as Array<string>;
        if (requiredRoles) {
          if (roles) {
            if (roles.includes(AppPermissions.SUPERADMIN)) {
              // ADMIN can do anything
              return true;
            }
            if (route.data.module === ModuleNameEnum.dashboard) {
              return this.checkIsFinalUser(route, roles);
            }
            for (const requiredRole of requiredRoles) {
              if (roles.includes(requiredRole)) {
                return true;
              }
            }
            // no role match
            return false;
          } else {
            // no roles at all
            return false;
          }
        } else {
          if (route.data.module === ModuleNameEnum.dashboard) {
            return this.checkIsFinalUser(route, roles);
          }
          return true;
        }
      })
    );
  }

  checkIsFinalUser(route: Route | ActivatedRouteSnapshot, roles: AppPermissions | string[]) {
    if (!environment.USER_HOME_ENABLED) {
      return false;
    }
    return isFinalUser(roles);
  }

  canFinalUserGo(route: Route | ActivatedRouteSnapshot) {
    return this.store.pipe(
      select(getMe),
      map(loggedUser => {
        if(!isFinalUser(loggedUser.rolesJson)) {
          return false;
        }

        if(isBaseUser(loggedUser)) {
          return true;
        } else {
          if(route.data?.baseUserOnly) {
            return false;
          }
          const requiredRoles = route.data?.roles as Array<string>;
          if(requiredRoles) {
            for (const requiredRole of requiredRoles) {
              if (loggedUser.rolesJson?.includes(requiredRole)) {
                return true;
              }
            }
            return false;
          } else {
            return true;
          }
        }
      })
    )
  }

  canUserSeeManagementSystems() {
    return this.store.pipe(
      select(getMe),
      map(loggedUser => {
        return loggedUser.rolesJson.includes(AppPermissions.CAN_HANDLE_MANAGEMENT_SYSTEMS) ||
          isBaseUser(loggedUser);
      })
    );
  }
}
