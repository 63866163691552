import {Component, OnInit} from '@angular/core';
import {CheckRoutes} from "../../../shared/enums/routes.enum";
import {ActivatedRoute} from "@angular/router";
import {PlannedOperationModel} from "../../../shared/models/planned-operation.model";
import {PlannedOperationService} from "../../../shared/services/planned-operation/planned-operation.service";
import {ArchiveService} from "../../services/archive.service";
import {FileSaverService} from "ngx-filesaver";
import {ConstructionService} from "../../../construction/services/construction/construction.service";
import {UserModel} from "../../../user/models/user.model";
import {ConstructionModel} from "../../../construction/models/construction.model";
import {BaseUserService} from "../../../shared/services/base-user/base-user.service";

@Component({
  selector: 'app-archive-downloader',
  templateUrl: './archive-downloader.component.html',
  styleUrls: ['../../../auth/components/login/login.component.scss']
})
export class ArchiveDownloaderComponent implements OnInit {
  CheckRoutes = CheckRoutes;
  loggedUser: UserModel;
  private plannedOperationId: string;
  private plannedOperation: PlannedOperationModel;
  construction: ConstructionModel;
  loading = false;
  constructionName: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private archiveService: ArchiveService,
    private fileSaverService: FileSaverService,
    private plannedOperationService: PlannedOperationService,
    private constructionService: ConstructionService,
    private baseUserService: BaseUserService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.loggedUser = await this.baseUserService.loggedUser();
    this.plannedOperationId = this.activatedRoute.snapshot.paramMap.get('plannedOperationId');
    this.plannedOperation = await this.plannedOperationService.getOne(this.plannedOperationId);
    this.construction = await this.constructionService.getOne(this.plannedOperation.data.construction_id).toPromise();
    this.constructionName = this.constructionService.getConstructionName(this.construction);
  }

  async downloadArchive() {
    this.loading = true;
    this.archiveService.downloadArchive(this.plannedOperationId).subscribe(
      (res) => {
        this.fileSaverService.save(res.body, `${this.constructionName}.zip`);
        this.loading = false;
      },
      (error) => {this.loading = false;}
    );
  }
}
