import { Action, createReducer, on } from '@ngrx/store';
import * as ActorAction from '../actions/actor.actions';
import { ActorModel } from '../models/actor.model';

export const actorFeatureKey = 'actor';

export interface State {
  actorModel: ActorModel | undefined;
  found: boolean | undefined;
  errorMessages: string[];
  successMessages: string[];
  index: number;
}

export const initialState: State = {
  actorModel: undefined,
  found: undefined,
  errorMessages: [],
  successMessages: [],
  index: -1,
};

const actorReducer = createReducer(
  initialState,
  on(ActorAction.actorClear, () => initialState),
  on(ActorAction.actorLoad, (state, action) => ({ ...state, actorModel: action.actorModel, found: undefined, errorMessages: [], successMessages: [], index: -1 })),
  on(ActorAction.actorFind, (state, action) => ({ ...state, actorModel: undefined, found: undefined, errorMessages: [], successMessages: [], index: action.index })),
  on(ActorAction.actorFound, (state, action) => {
    return { ...state, actorModel: action.actorModel, found: true, errorMessages: [], successMessages: [] };
  }),
  on(ActorAction.actorNotFound, (state, action) => ({ ...state, actorModel: undefined, found: false, errorMessages: [], successMessages: [], index: -1 })),

  // on(ActorAction.actorCreate, (state, action) =>
  // {
  //   return {...state, actorModel: action.actorModel, found: undefined, errorMessages: [], successMessages: []}
  // }),
  on(ActorAction.actorCreatedSuccess, (state, action) => ({ ...state, actorModel: action.actorModel, found: undefined, errorMessages: action.errorMessages, successMessages: action.successMessages })),
  on(ActorAction.actorCreatedError, (state, action) => ({ ...state, actorModel: undefined, found: undefined, errorMessages: action.errorMessages, successMessages: [] })),

  on(ActorAction.actorUpdatedSuccess, (state, action) => ({ ...state, actorModel: action.actorModel, found: undefined, errorMessages: action.errorMessages, successMessages: action.successMessages })),
  on(ActorAction.actorUpdatedError, (state, action) => ({ ...state, actorModel: undefined, found: undefined, errorMessages: action.errorMessages, successMessages: [] }))
);

export function reducer(state: State | undefined, action: Action) {
  return actorReducer(state, action);
}
