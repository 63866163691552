import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActorValidatorService} from "../../../shared/services/actor-validator/actor-validator.service";
import {UserService} from "../../../user/services/user/user.service";
import {environment} from "../../../../environments/environment";
import {MessageModel} from "../../../shared/models/message.model";
import { CommonRoutes } from 'src/app/shared/enums/routes.enum';

@Component({
  selector: 'app-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.scss'],
})
export class UserActivationComponent  implements OnInit {

  messages: MessageModel[] = [];
  form = this.fb.group({
    fiscalCode: [undefined, Validators.required],
  });
  captchaResponse;

  RECAPTCHA_SITE_KEY = environment.RECAPTCHA_SITE_KEY;
  CommonRoutes = CommonRoutes;

  constructor(
      private fb : FormBuilder,
      private actorValidatorService: ActorValidatorService,
      private userService: UserService,
  ) { }

  ngOnInit(): void {
  }

  async send() {
    this.form.markAllAsTouched();
    if(this.form.valid && this.captchaResponse) {
      this.messages = [];
      try{
        if(this.actorValidatorService.verifyIfCorrectFiscalCodeOrVatExc(this.form.get('fiscalCode')?.value)) {
          const message = await this.userService.userActivationRequest(this.form.get('fiscalCode')?.value, this.captchaResponse);
          this.messages.push(new MessageModel(message.label, message.error, message.code, null, message.variables));
        }
      } catch(e) {
        this.messages.push(new MessageModel(e, true));
      }
    }
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
  }

}
