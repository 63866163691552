import { Component, OnInit } from '@angular/core';
import {UserService} from "../../../user/services/user/user.service";
import {ActivatedRoute} from "@angular/router";
import {MessageModel} from "../../../shared/models/message.model";
import {CommonRoutes} from "../../../shared/enums/routes.enum";

@Component({
  selector: 'app-user-confirm',
  templateUrl: './user-confirm.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class UserConfirmComponent implements OnInit {

  loading = false;
  messages : MessageModel[] = [];
  CommonRoutes = CommonRoutes;
  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
  ) { }

  async ngOnInit(): Promise<void> {
    const confirmationToken = this.activatedRoute.snapshot.paramMap.get('confirmationToken');
    if(confirmationToken) {
      const messageModel = await this.userService.confirm(confirmationToken);
      this.messages.push(messageModel);
    } else {
      this.messages.push(new MessageModel(`Impossibile recuperare l'utente`, true));
    }
  }
}
