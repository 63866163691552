import { Injectable } from '@angular/core';
import { ConstructionModel } from '../../construction/models/construction.model';
import { ActorModel } from '../../actor/models/actor.model';
import { NotificationModel } from '../../notification/models/notification.model';

@Injectable()
export class StateService {
  public constructionObject!: ConstructionModel;
  public actorObject!: ActorModel;
  public actorObjects!: ActorModel[];
  public notificationObject!: NotificationModel;
}
