import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { CookieService } from 'ngx-cookie';
import { getMe } from '../../../auth/selectors/auth.selector';
import { take } from 'rxjs/operators';
import { CookiesEnum } from '../../enums/cookies.enum';
import { environment } from '../../../../environments/environment';
import {NavigationEnd, Router} from "@angular/router";
import {GoogleTagManagerService} from "angular-google-tag-manager";

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(
      private store: Store<AppState>,
      private cookieService: CookieService,
      private gtmService: GoogleTagManagerService,
      public router: Router,
  ) {}

  canAddGTM() {
    return environment.production && this.cookieService.get(CookiesEnum.AnalyticCookieKey) === 'true';
  }

  pushTagForPageLinks() {
    this.router.events.forEach(item => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };
        this.gtmService.pushTag(gtmTag);
      }
    });
  }

  areScriptLoaded(): boolean {
    return this.cookieService.get(CookiesEnum.LoadedScripts) === 'true';
  }

  loadScripts(): void {
    if (environment.production && !this.areScriptLoaded() && this.cookieService.get(CookiesEnum.AnalyticCookieKey) === 'true') {
      const node1 = document.createElement('script'); // creates the script tag
      node1.type = 'text/javascript'; // set the script type
      node1.async = true; // makes script run asynchronously
      node1.src = 'https://www.google.analytics.com/analytics.js';
      // append to head of document
      document.getElementsByTagName('head')[0].appendChild(node1);

      const node2 = document.createElement('script'); // creates the script tag
      node2.async = true; // makes script run asynchronously
      node2.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GOOGLE_TAG_ID;
      // append to head of document
      document.getElementsByTagName('head')[0].appendChild(node2);

      const node3 = document.createElement('script'); // creates the script tag
      node3.text = ' window.dataLayer = window.dataLayer || [];\n' + '      function gtag(){dataLayer.push(arguments);}\n' + "      gtag('js', new Date());\n";

      document.getElementsByTagName('head')[0].appendChild(node3);
      return this.cookieService.put(CookiesEnum.LoadedScripts, 'true');
    }
  }

  async putTag(): Promise<void> {
    const user = await this.store.select(getMe).pipe(take(1)).toPromise();
    if (environment.production && this.cookieService.get(CookiesEnum.AnalyticCookieKey) === 'true') {
      if (user) {
        gtag('config', environment.GOOGLE_TAG_ID, { user_id: user.id });
      } else {
        gtag('config', environment.GOOGLE_TAG_ID);
      }
    }
  }
}
