export enum ConstructionState {
  unknown = 'ignoto',
  onEvaluation = 'in_valutazione',
  worksNotStarted = 'lavori_non_iniziati',
  active = 'attivo',
  inactive = 'inattivo',
  suspended = 'sospeso',
  closed = 'chiuso',
}

export enum ConstructionStateLabels {
  unknown = 'Ignoto',
  onEvaluation = 'In valutazione',
  worksNotStarted = 'Lavori non inziati',
  active = 'Attivo',
  inactive = 'Inattivo',
  suspended = 'Sospeso',
  closed = 'Chiuso',
  ignoto = 'Ignoto',
  in_valutazione = 'In valutazione',
  lavori_non_iniziati = 'Lavori non inziati',
  attivo = 'Attivo',
  inattivo = 'Inattivo',
  sospeso = 'Sospeso',
  chiuso = 'Chiuso',
}

export class ConstructionStateTrait {
  public static constructionStateToLabel(name: string) {
    const cap = name.charAt(0).toUpperCase() + name.slice(1);
    return cap.replace(/_/g, ' ');
  }

  public static constructionStates() {
    return [ConstructionState.unknown, ConstructionState.onEvaluation, ConstructionState.worksNotStarted, ConstructionState.active, ConstructionState.inactive, ConstructionState.suspended, ConstructionState.closed];
  }

  public static constructionStatesValuesLabels() {
    const states = ConstructionStateTrait.constructionStates();
    const valueLabels = [];

    for (const state of states) {
      valueLabels.push({ value: state, title: ConstructionStateTrait.constructionStateToLabel(state) });
    }
    return valueLabels;
  }
}
