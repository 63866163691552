import {Injectable} from '@angular/core';
import {AppState} from "../../../reducers";
import {Store} from "@ngrx/store";
import {getMe} from "../../../auth/selectors/auth.selector";
import {take} from "rxjs/operators";
import {AppPermissions} from "../../../user/enum/app-permissions.enum";
import {UserModel} from "../../../user/models/user.model";
import {ActorModel} from "../../../actor/models/actor.model";
import {ActorService} from "../../../actor/services/actor/actor.service";

@Injectable({
  providedIn: 'root'
})
export class BaseUserService {

  constructor(
    private store: Store<AppState>,
  ) { }

  async isLoggedUserBaseUser() {
    const loggedUser = await this.loggedUser();
    return isBaseUser(loggedUser);
  }

  async loggedUser() {
    return await this.store.select(getMe).pipe(take(1)).toPromise();
  }

  async isSuperadminOrCape() {
    const loggedUser = await this.loggedUser();
    return  loggedUser.rolesJson?.includes(AppPermissions.SUPERADMIN) ||
      loggedUser.rolesJson?.includes(AppPermissions.SUPPORT);
  }

  async isLoggedUserGuest(): Promise<boolean> {
    const loggedUser = await this.loggedUser();
    return loggedUser.rolesJson.includes(AppPermissions.GUEST)
  }
}

export function isSupportUser (roles?: AppPermissions | string[]) {
  if(!roles) return false;
  return  roles.includes(AppPermissions.SUPPORT) ||
          roles.includes(AppPermissions.SUPERADMIN) ||
          roles.includes(AppPermissions.ROLE_BOARD_ADMIN) ||
          roles.includes(AppPermissions.GUEST)
}

export function isFinalUser (roles?: AppPermissions | string[]) {
  return !isSupportUser(roles);
}

export function isBaseUser(user: UserModel) {
  return user.id == user.baseUserId;
}

export function isUserBaseUserOfActor(user: UserModel, actor: ActorModel) {
  return actor.actorUserId == user.id;
}
