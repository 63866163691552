export enum CommonRoutes {
  CREATE = 'create',
  PUBLIC = 'public',
  PRIVACY = 'privacy',
  LOGIN = 'login',
  MY_DATA = 'my-data',
  REQUEST_PASSWORD = 'request-password',
  RESET_PASSWORD = 'reset-password',
  CHANGE_USERNAME_PASSWORD = 'change-username-password',
  USER_CONFIRM = 'user-confirm',
  DOWNLOAD_ARCHIVE = 'download-archive',
  NEW_USER = 'new-user',
  EDIT = 'edit',
}

export enum CheckRoutes {
  HOME = 'home',
  USER = 'dashboard',
  BOARD = '',
  ACTOR = 'actor',
  ACTOR_EMPLOYEE = 'actor-employee',
  EQUIPMENT = 'equipment',
  CONSTRUCTION = 'construction',
  OFFICE = 'office',
  DOCUMENT = 'document',
  STANDARDS = 'standards',
  SAFETY_DECALOGUE = 'safety-decalogue',
  SECONDARY_USER = 'secondary-user',
  OPTIONS = 'options',
  WINDOW = 'window',
  ACTIVATE_ACTOR_IN_CONSTRUCTION = 'activate-actor-in-construction',
  ACTOR_CERTIFICATION = 'actor-certification',
  ACTOR_EMPLOYEE_STAFF_CARD = 'staff-card',
  ANOMALY = 'anomaly',
  DEADLINE = 'deadline',
  ACTIVITY = 'activity',
  ASSESSMENT = 'assessment',
  CHECKLIST = 'checklist',
  NC = 'nc',
  QUESTION = 'question',
  DELETE_ACCOUNT = 'eliminazione-account',
  CONSTRUCTION_LEGEND = 'legend',
  CONSTRUCTION_ACTOR_LEGEND = 'actors-legend',
  ARCHIVED_CONSTRUCTIONS = 'archived',
  OFFICE_STOREFRONT = 'storefront',
  CHECK_COURSE = 'check-course'
}
