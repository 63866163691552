import {CodeMessageLabel} from "../enums/code-message-label.enum";

export class MessageModel {
  public label!: string;
  error!: boolean;
  code?: string;
  warning = false;
  variables? : any[];

  constructor(label: string | CodeMessageLabel, error: boolean, code?: string, warning?: boolean, variables?: any[]) {
    this.label = label;
    this.error = error;
    this.code = code;
    this.warning = warning;

    if(code) {
      this.code = code;
      this.label = CodeMessageLabel[code];
    }

    if(label) this.label = label;

    this.error = error;
    this.variables = variables;
  }
}
