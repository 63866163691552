import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ActorValidatorService {
  constructor() {}

  verifyIfCorrectFiscalCodeOrVat(str: string): string {
    try {
      if(this.verifyIfCorrectFiscalCodeOrVatExc(str)) {
        return ``;
      }
    } catch(exc) {
      return exc.message;
    }
  }

  verifyIfCorrectFiscalCodeOrVatExc(str: string): boolean {
    if (str.length == 11 && this.isVatValidExc(str)) {
      return true;
    }

    if (this.isFiscalCodeValidExc(str)) {
      return true;
    }
    return false;
  }

  isFiscalCodeValid(fiscalCode: string): boolean {
    try {
      this.isFiscalCodeValidExc(fiscalCode);
      return true;
    } catch (exc) {
      return false;
    }
  }

  isVatValid(vat: string): boolean {
    try {
      this.isVatValidExc(vat);
      return true;
    } catch (exc) {
      return false;
    }
  }

  /**
   * Controlla codice fiscale.
   *
   * @param cf string Codice fiscale costituito da 16 caratteri (11 se è un'impresa). Non
   *                   sono ammessi caratteri di spazio, per cui i campi di input dell'utente
   *                   dovrebbero essere trimmati preventivamente. La stringa vuota e' ammessa,
   *                   cioe' il dato viene considerato opzionale.
   *
   * @return boolean true se il codice di controllo del C.F.  e'
   *                 corretto oppure la stringa di input e' vuota. Altrimenti ritorna un'eccezione
   *                 ti tipo InvalidFiscalCodeException con un
   *                 messaggio che descrive il problema.
   */
  isFiscalCodeValidExc(cf: string): boolean {
    // Se è un'impresa individuale, CF e p.iva sono diversi,
    // altrimenti potrebbero sia essere uguali che diversi

    if (cf === '') {
      return true;
    }

    if (cf.length != 16) {
      throw new Error('La lunghezza del codice fiscale non è corretta: il codice fiscale dovrebbe essere lungo esattamente 16 caratteri.');
    }

    cf = cf.toUpperCase();
    const regex = new RegExp(/^[A-Z0-9]+$/);

    if (!regex.test(cf)) {
      throw new Error('Il codice fiscale contiene dei caratteri non validi: i soli caratteri validi sono le lettere e le cifre.');
    }

    let s = 0;
    for (let i = 1; i <= 13; i += 2) {
      let c = cf[i];
      if (c >= '0' && c <= '9') {
        s += c.charCodeAt(0) - '0'.charCodeAt(0);
      } else {
        s += c.charCodeAt(0) - 'A'.charCodeAt(0);
      }
    }
    for (let i = 0; i <= 14; i += 2) {
      let c = cf[i];
      switch (c) {
        case '0':
          s += 1;
          break;
        case '1':
          s += 0;
          break;
        case '2':
          s += 5;
          break;
        case '3':
          s += 7;
          break;
        case '4':
          s += 9;
          break;
        case '5':
          s += 13;
          break;
        case '6':
          s += 15;
          break;
        case '7':
          s += 17;
          break;
        case '8':
          s += 19;
          break;
        case '9':
          s += 21;
          break;
        case 'A':
          s += 1;
          break;
        case 'B':
          s += 0;
          break;
        case 'C':
          s += 5;
          break;
        case 'D':
          s += 7;
          break;
        case 'E':
          s += 9;
          break;
        case 'F':
          s += 13;
          break;
        case 'G':
          s += 15;
          break;
        case 'H':
          s += 17;
          break;
        case 'I':
          s += 19;
          break;
        case 'J':
          s += 21;
          break;
        case 'K':
          s += 2;
          break;
        case 'L':
          s += 4;
          break;
        case 'M':
          s += 18;
          break;
        case 'N':
          s += 20;
          break;
        case 'O':
          s += 11;
          break;
        case 'P':
          s += 3;
          break;
        case 'Q':
          s += 6;
          break;
        case 'R':
          s += 8;
          break;
        case 'S':
          s += 12;
          break;
        case 'T':
          s += 14;
          break;
        case 'U':
          s += 16;
          break;
        case 'V':
          s += 10;
          break;
        case 'W':
          s += 22;
          break;
        case 'X':
          s += 25;
          break;
        case 'Y':
          s += 24;
          break;
        case 'Z':
          s += 23;
          break;
        /*. missing_default: .*/
      }
    }
    if (String.fromCharCode((s % 26) + 'A'.charCodeAt(0)) != cf[15]) {
      throw new Error('Il codice fiscale non è corretto: il codice di controllo non corrisponde.');
    }

    return true;
  }

  /**
   * Controlla partita IVA.
   *
   * @param pi string  Partita IVA costituita da 11 cifre. Non sono ammessi
   *                   caratteri di spazio, per cui i campi di input dell'utente dovrebbero
   *                   essere trimmati preventivamente. La stringa vuota e' ammessa, cioe'
   *                   il dato viene considerato opzionale.
   *
   * @return boolean true se il codice di controllo della partita IVA
   *                 e' corretto oppure la stringa di input e' vuota. Altrimenti ritorna un'eccezione
   *                 ti tipo InvalidVatException con un
   *                 messaggio che descrive il problema.
   */

  isVatValidExc(pi: string): boolean {
    if (pi == null || pi.trim() === '') {
      return true;
    }

    if (pi.length != 11) {
      throw new Error('La lunghezza della partita IVA non è corretta: la partita IVA dovrebbe essere lunga esattamente 11 caratteri.');
    }

    const regex = new RegExp(/^[0-9]+$/);
    if (!regex.test(pi)) {
      throw new Error('La partita IVA contiene dei caratteri non ammessi: la partita IVA dovrebbe contenere solo cifre.');
    }

    let s = 0;
    for (let i = 0; i <= 9; i += 2) {
      s += pi[i].charCodeAt(0) - '0'.charCodeAt(0);
    }

    for (let i = 1; i <= 9; i += 2) {
      let c = 2 * (pi[i].charCodeAt(0) - '0'.charCodeAt(0));
      if (c > 9) {
        c = c - 9;
      }

      s += c;
    }
    if ((10 - (s % 10)) % 10 != pi[10].charCodeAt(0) - '0'.charCodeAt(0)) {
      throw new Error('La partita IVA non è valida: il codice di controllo non corrisponde');
    }

    return true;
  }
}
