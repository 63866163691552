import { createAction, props } from '@ngrx/store';
import { LoginDto, LoginResponseDto } from '../models/login.model';
import { UserModel } from '../../user/models/user.model';

export const login = createAction('[Auth] Submit login', props<LoginDto>());

export const loginSuccess = createAction('[Auth] Login Success', props<LoginResponseDto>());

export const loginError = createAction('[Auth] Login Failure', props<{ error: string }>());

export const storeTokens = createAction('[Auth] Store tokens and user', props<LoginResponseDto>());

export const logout = createAction('[Auth] User logout');

export const logoutSuccess = createAction('[Auth] User logged out');

export const acceptTerms = createAction('[Auth] Submit accept terms');

export const acceptTermsSuccess = createAction('[Auth] Privacy Terms accept Success', props<UserModel>());

export const acceptTermsError = createAction('[Auth] Privacy Terms accept Fail');

export const refreshAuthData = createAction('[Auth] Refresh Auth Data');

export const refreshAuthDataSuccess = createAction('[Auth] Refresh Auth Data Success', props<LoginResponseDto>());

export const refreshAuthDataError = createAction('[Auth] Refresh Auth Data Error', props<{ error: string }>());

export const impersonate = createAction('[Auth] Impersonate user', props<{userToImpersonateId: number}>());

export const impersonateSuccess = createAction('[Auth] Impersonate user Success', props<LoginResponseDto>());

export const showError = createAction('[Auth] showError', props<{ error: string }>());

export const recoverChatData = createAction('[Auth] Recovering user chat data', props<UserModel>());

export const firebaseSignIn = createAction('[Auth] FIREBASE Signing In', props<{chatToken: string, userChatUID: string, email: string}>());
