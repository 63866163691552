import {Injectable} from '@angular/core';
import {AngularFirestore, CollectionReference, DocumentData, Query} from '@angular/fire/compat/firestore';
import {Observable, of} from 'rxjs';
import {FirebaseGroupModel} from '../../models/firebase-group.model';
import {FirebaseChatNotificationDevicesModel} from '../../models/firebase-chat-notification-devices.model';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {catchError, first, map} from 'rxjs/operators';
import {ChatGroupService} from '../chat-group/chat-group.service';
import {WhereFilterOp} from '@angular/fire/firestore';
import {environment} from "../../../../environments/environment";
import {ChatGroupModel} from "../../models/chat-group.model";
import {FilterableMessageType} from "../../enums/filterable-message-type.enum";
import {SharableEntity, SharedMessageModel} from "../../models/shared-entity.model";
import {ChatMessageActionType} from "../../enums/chat-message-type.enum";
import {BaseUserService, isSupportUser} from "../../../shared/services/base-user/base-user.service";
import {TagModel} from "../../../shared/tags/models/tag.model";
import {UtilsService} from "../../../shared/services/utils/utils.service";
import {FilterableObj} from "../../../shared/models/filterable-obj.model";
import {EntityDataState} from "../../enums/message-state.enum";

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private currentChatGroupUID: string;
  defaultLimit = 10;

  constructor(
    private afs: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private readonly baseUserService: BaseUserService,
    private chatGroupService: ChatGroupService,
    private readonly utilsService: UtilsService
  ) {
  }

  setCurrentChatGroupUID(uid) {
    this.currentChatGroupUID = uid;
  }

  getCurrentChatGroupUID() {
    return this.currentChatGroupUID;
  }


  async signInWithEmailAndPassword(email: string, chatToken: string): Promise<void> {
    const loggedUser = await this.baseUserService.loggedUser();
    if(isSupportUser(loggedUser.rolesJson)) {
      console.log(`Not logging support user into firebase`);
      return;
    }

    const emailToUse = this.emailToUse(email);
    if (!(await this.angularFireAuth.user.pipe(first()).toPromise())?.uid) {
      await this.angularFireAuth.signInWithEmailAndPassword(emailToUse, chatToken);
    }
  }

  emailToUse(email: string): string {
    const env = environment.env;
    if(env == 'prod') return email;
    // if(env == 'prod' || env == 'local') return email;

    const devHash = environment.devHash || '';
    // if(environment == 'prod' || environment == 'local') return email;
    console.log(devHash);
    const emailToUse = `${devHash}${env}${email}`;
    console.log(`Utilizzo email diversa per ambiente non prod: ${emailToUse} per ${email}`);
    return emailToUse;

    // const separator = '@';
    // let emailToUse;
    // if (email.includes(separator)) { // es: prima@dopo
    //   const split = email.split('@');
    //   emailToUse = `${split[0]}${separator}${env}domain.com`;
    // } else { // es: prima
    //   emailToUse = `${email}${separator}${env}domain.com`
    // }
    // console.log(`Utilizzo email diversa per ambiente non prod: ${emailToUse} per ${email}`);
    // return emailToUse; // prima@envdomain.com
  }

  async logout() {
    if ((await this.angularFireAuth.user.pipe(first()).toPromise())?.uid) {
      await this.angularFireAuth.signOut();
    }
  }

  queryFirebase(ref: CollectionReference<DocumentData> | Query<any>, limit?: number, startAfter?: any,
                whereClauses?: { field: string, operator: WhereFilterOp, value: any }[], defaultSort = true, customSort?: string[]) {
    let query;

    // console.log(whereClauses)

    if (whereClauses?.length > 0) {
      let i = 0;
      query = ref.where(whereClauses[i].field, whereClauses[i].operator, whereClauses[i].value);
      for (let i = 1; i < whereClauses.length; i++) {
        query = query.where(whereClauses[i].field, whereClauses[i].operator, whereClauses[i].value);
      }
    } else {
      query = ref;
    }

    if (defaultSort) {
      query = query.orderBy('createdAt', 'desc');
    }

    if(customSort) {
      query = query.orderBy(customSort[0], customSort[1]);
    }

    if (startAfter) {
      query = query.startAfter(startAfter);
    }

    if (limit) {
      query = query.limit(limit);
    }
    return query;
  }

  getMessagesWhere(limit?: number, startAfter?: any, whereClauses?: {
    field: string,
    operator: WhereFilterOp,
    value: any
  }[], defaultSort?: boolean) {
    return this.afs
      .collectionGroup('messages', (query: Query<any>) => {
        return this.queryFirebase(query, limit, startAfter, whereClauses, defaultSort);
      })
      .get()
      .toPromise();
  }

  chatMessagesWhere(groupId: string, limit?: number, startAfter?: any, whereClauses?: {
    field: string,
    operator: WhereFilterOp,
    value: any
  }[], defaultSort?: boolean, customSort?: string[]) {
    return this.afs
      .collection('chat')
      .doc(groupId)
      .collection('messages', (ref: CollectionReference<DocumentData>) => {
        return this.queryFirebase(ref, limit, startAfter, whereClauses, defaultSort, customSort);
      })
  }

  getChatMessages(groupId: string, limit?: number, startAfter?: any, retrying?: boolean,
                  whereClauses?: {
                    field: string,
                    operator: WhereFilterOp,
                    value: any
                  }[], defaultSort?: boolean, customSort?: string[]): Promise<any> {
    return this.chatMessagesWhere(groupId, limit, startAfter, whereClauses, defaultSort, customSort)
      .get()
      .pipe(
        map(result => result),
        catchError(async err => {
          // if request fails I try signout the user and sign it back in (just once)
          if (!retrying) {
            const user = await this.baseUserService.loggedUser();
            await this.logout();
            let chatInfo = await this.chatGroupService.getChatInfo();
            return await this.getChatMessages(groupId, limit, startAfter, true, whereClauses, defaultSort, customSort);
          }
          return of('error', err);
        })
      )
      .toPromise();
  }

  listenChatMessages(groupId: string, limit?: number, startAfter?: any, retrying?: boolean,
                     whereClauses?: {
                       field: string,
                       operator: WhereFilterOp,
                       value: any
                     }[], defaultSort?: boolean): Observable<any> {
    return this.chatMessagesWhere(groupId, limit, startAfter, whereClauses, defaultSort)
      .valueChanges({idField: 'id'});
  }

  // snapshot (groupId: string, limit?: number, startAfter?: any, retrying?: boolean,
  //           whereClauses?: {field: string, operator: WhereFilterOp, value: any}[]) {
  //   return this.chatMessagesWhere(groupId, limit, startAfter, whereClauses)
  //     .snapshotChanges()
  //     .pipe(
  //       map(actions => {
  //         actions.map(a => a.payload.doc.data());
  //         console.log(actions)
  //       })
  //     );
  // }

  getMessageById(id: string, groupId: string) {
    return this.afs.collection('chat').doc(groupId).collection('messages').doc(id).get().toPromise();
  }

  addMessage(groupId: string, message): Promise<any> {
    return this.afs.collection('chat').doc(groupId).collection('messages').add(message);
  }

  updateMessage(groupId: string, messageId: string, message) {
    return this.afs.collection('chat').doc(groupId).collection('messages').doc(messageId).update(message);
  }

  async removeEntityDataSharing(message: SharedMessageModel) {
    let text;
    if(message.content.text) {
      text = JSON.parse(JSON.stringify(message.content.text));
    }

    message.content = {};

    if(text) {
      message.content.text = text;
    }
    message.contentState = {};
    message.contentState.entityData = EntityDataState.SHARE_REMOVED;
    await this.updateMessage(message.chatGroupId, message.id, message);
    return message;
  }

  // addTags(messageId: string, tags: TagModel[]) {
  //   return this.afs.collection('messages').doc(messageId).collection('tags').add(tags);
  // }

  addFirebaseGroup(group: FirebaseGroupModel): Promise<any> {
    return this.afs.collection('chat').add(group);
  }

  getFirebaseGroup(groupId: string): Promise<any> {
    return this.afs.collection('chat').doc(groupId).get().toPromise();
  }

  updateFirebaseGroup(groupId: string, group: FirebaseGroupModel): Promise<any> {
    return this.afs.collection('chat').doc(groupId).update(group);
  }

  getFirebaseNotificationDevices(userUID: string): Promise<any> {
    return this.afs.collection('chat-notification-devices').doc(userUID).get().toPromise();
  }

  addFirebaseNotificationDevices(userUID: string, notificationDevices: FirebaseChatNotificationDevicesModel): Promise<any> {
    return this.afs.collection('chat-notification-devices').doc(userUID).set(notificationDevices);
  }

  updateFirebaseNotificationDevices(userUID: string, notificationDevices: FirebaseChatNotificationDevicesModel): Promise<any> {
    return this.afs.collection('chat-notification-devices').doc(userUID).update(notificationDevices);
  }

  async removeUserFromFirebaseGroup(userUid: string, groupId: string, group: FirebaseGroupModel) {

    this.utilsService.removeElementFromArray(userUid, group.allowedReadUsers);
    this.utilsService.removeElementFromArray(userUid, group.allowedWriteUsers);
    this.utilsService.removeElementFromArray(userUid, group.admins);

    if (group.owner === userUid) {
      group.owner = '';
    }
    await this.updateFirebaseGroup(groupId, group);
  }

  createFirebaseDateTime() {
    const seconds = Math.round(new Date().getTime() / 1000);
    const nanosec = new Date().getTime() * 1000 * 1000;
    return { seconds: seconds, nanoseconds: nanosec }
  }

  baseMessageData(message: any) {

    return {
      ...message,
      createdAt: this.createFirebaseDateTime(),
    }
  }

  mapTagsForFirebase(tags: TagModel[]) {
    const tagsForFirebase = [];
    for(const tag of tags) {
      tagsForFirebase.push(this.mapTagForFirebase(tag));
    }
    return tagsForFirebase;
  }

  mapTagForFirebase(tag: TagModel) {
    return {
      id: tag.id,
      label: tag.label,
      icon: tag.icon,
      color: tag.color
    }
  }

  chatGroupMessageData(message: any, group: { chatGroup: ChatGroupModel, firebaseGroup: any }) {
    return {
      ...message,
      chatGroupId: group.chatGroup.uid,
      chatGroupName: group.chatGroup.groupName,
    }
  }

  typeFilters(selectedType: FilterableObj, filterWhereClauses: {
    field: string,
    operator: WhereFilterOp,
    value: any
  }[]): { field: string, operator: WhereFilterOp, value: any }[] {
    if (selectedType) {
      let clause;
      if (selectedType.sharableEntity == SharableEntity.CCO) {
        clause = {field: 'content.entityData.type', operator: '==', value: selectedType.key.toLowerCase()};
      } else if (selectedType.key == FilterableMessageType.EQUIPMENT || selectedType.key == FilterableMessageType.DOCUMENT ||
      selectedType.key == FilterableMessageType.ACTIVITY) {
        clause = {field: 'content.entityName', operator: '==', value: selectedType.sharableEntity}
      } else if (selectedType.key == FilterableMessageType.TEXT) {
        clause = {field: 'type', operator: '==', value: ChatMessageActionType.new_text}
      }

      if (clause) {
        filterWhereClauses.push(clause);
      }
    }
    return filterWhereClauses;
  }
}
