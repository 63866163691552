import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private _snackBar: MatSnackBar) {}

  isEmptyObject (empty: any) { // {}
    return JSON.stringify(empty) === "{}";
  }

  openSnackbar(message: string, duration?: number, stringClass?: string) {
    this._snackBar.open(message, 'OK', {
      duration: 3000,
      panelClass: stringClass ? [stringClass] : '',
    });
  }

  removeDuplicates(array: string[]) {
    return [...new Set(array)];
  }

  unauthorizedMessage() {
    this._snackBar.open(`Non sei autorizzato a visualizzare questa risorsa`, 'OK', { duration: 10000 });
  }

  removeElementFromArray(element: any, array: any[], ) {
    const index = array.indexOf(element);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  fromStringToNumberArray(stringToConvert: string) {
    if (!stringToConvert?.length) {
      return [];
    }

    const containerString = stringToConvert.substring(1, stringToConvert.length - 1);
    const contained = containerString.split(',');
    if (contained.length > 0) {
      return contained.map(c => parseInt(c))
    } else {
      return [];
    }
  }
}
