import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CookieService } from 'ngx-cookie';
import { CookiesEnum } from '../../../shared/enums/cookies.enum';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss'],
})
export class CookiePolicyComponent implements OnInit {
  selectedAnalyticCookie = true;
  selectedTechnicalCookie = true;

  constructor(public dialogRef: MatDialogRef<CookiePolicyComponent>, private cookieService: CookieService) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
  }

  onOkClicked() {
    this.cookieService.put(CookiesEnum.AnalyticCookieKey, this.selectedAnalyticCookie.toString(), { expires: new Date('2999-01-01') });
    this.cookieService.put(CookiesEnum.TechnicalCookieKey, this.selectedTechnicalCookie.toString(), { expires: new Date('2999-01-01') });
    this.dialogRef.close();
  }

  onRejectClicked() {
    this.selectedAnalyticCookie = false;
    this.onOkClicked();
  }
}
