import {CanLoad, Route, UrlSegment} from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { RolesGuardService } from '../../services/roles-guard/roles-guard.service';

@Injectable()
export class CanLoadRoleGuard implements CanLoad {
  constructor(private rolesGuardService: RolesGuardService) {}

  // CanLoad serve ad evitare di caricare un intero modulo lazy (loadChildren) se l'utente loggato non ne ha i permessi

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.rolesGuardService.canUserGo(route);
  }
}
