<div class="dialog-header">
  <div class="dialog-title">
    {{ data.dialogTitle }}
    <img src="../../../../assets/img/icons/logos/check-logo.svg"/>
  </div>
  <img src="/assets/img/icons/xmark.svg"
       class="btn-close-img"
       mat-dialog-close
       *ngIf="!data.disableClose"/>
</div>

<mat-dialog-content class="mat-body" [innerHTML]="data.description"></mat-dialog-content>

<mat-dialog-actions align="end" class="dialog-actions">
  <button color="primary"
          mat-flat-button
          (click)="window.open(
          platform == PlatformEnum.ios
          ? environment.APP_STORE_PATH :
          environment.GOOGLE_PLAY_PATH,
          '_blank')">
    {{ data.storeBtnLabel }}
  </button>
</mat-dialog-actions>
