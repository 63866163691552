import {Injectable} from '@angular/core';
import {CanLoad, Route, Router, UrlSegment} from '@angular/router';
import {Store} from '@ngrx/store';
import {map, take} from 'rxjs/operators';
import {AppPermissions} from 'src/app/user/enum/app-permissions.enum';
import {AppState} from '../../../reducers';
import {canNavigate, getRoles} from '../../selectors/auth.selector';
import {CheckRoutes} from "../../../shared/enums/routes.enum";

@Injectable()
export class CanLoadGuard implements CanLoad {
  constructor(private store: Store<AppState>, private router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]) {
    return this.store.select(canNavigate).pipe(
      take(1),
      map(can => {
        // debugger
        if (!can) {
          if(localStorage.getItem(`isRefreshing`) !== 'true') {
            const callbackUrl = location.href;
            this.router.navigate(['/login'], { queryParams: { callbackUrl } });
          }
          // this.store.dispatch(logout());
          return false;
        }

        this.store.select(getRoles).subscribe(roles => {
          let shouldRedirectToUserHome = false;
          if (!roles || (!roles.includes(AppPermissions.ROLE_BOARD_ADMIN) && !roles.includes(AppPermissions.SUPPORT) && !roles.includes(AppPermissions.SUPERADMIN)  && !roles.includes(AppPermissions.GUEST))) {
            shouldRedirectToUserHome = true;
          }
          if (shouldRedirectToUserHome && route.data.module === 'home') {
            this.router.navigateByUrl(`/${CheckRoutes.USER}`);
          }
        });

        return true;
      })
    );
  }
}
