import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {UserService} from "../../../user/services/user/user.service";
import {MessageModel} from "../../../shared/models/message.model";
import {CodeMessage} from "../../../shared/enums/code-message.enum";
import {CommonRoutes} from "../../../shared/enums/routes.enum";

@Component({
  selector: 'app-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class RequestPasswordComponent  {

  messages: MessageModel[] = [];
  hideForm = false;

  CommonRoutes = CommonRoutes;
  form = new UntypedFormGroup({
    username: new UntypedFormControl('', [Validators.required]),
  });
  constructor(
    private userService: UserService,
  ) { }

  async request() {
    this.messages = [];
    this.form.markAllAsTouched();
    if(this.form.valid) {
      const messageModel = await this.userService.requestPassword(this.form.get('username').value);
      if(messageModel.code == CodeMessage.PASSWORD_ALREADY_REQUESTED || messageModel.code == CodeMessage.PASSWORD_REQUESTED) {
        this.hideForm = true;
      }
      this.messages.push(messageModel);
    }
  }

  goBack() {
    window.history.back();
  }

}
