<div class="login-container check-flex-col check-justify-center check-items-center">
  <div class="white-box-container">
    <div class="login-header check-flex-col check-space-evenly check-items-center">
      <img src="../../../../assets/img/icons/logos/logo.png"/>
      <h1 style="margin-top: 20px"><a routerLink="/{{CommonRoutes.LOGIN}}">Il Portale del Cantiere</a></h1>
    </div>
    <div>
      <app-alert-messages-box [messages]="messages"></app-alert-messages-box>

      <div *ngIf="messages.length == 0">
        <h3 class="form-title">Crea il tuo account</h3>
        <div [formGroup]="form">
          <label>Inserire il proprio Codice fiscale</label>
          <mat-form-field appearance="fill" class="fill-space">
            <input formControlName="fiscalCode" matInput placeholder="Codice fiscale">
            <mat-error *ngIf="form.get('fiscalCode')?.hasError('required')">Dato obbligatorio</mat-error>
          </mat-form-field>

          <button style="margin-top: 10px" mat-flat-button color="primary" (click)="send()" *ngIf="captchaResponse">
            Invia (le verrà inviata un'email di conferma)
          </button>
        </div>

        <div style="margin-top:10px" *ngIf="!captchaResponse">
          <re-captcha (resolved)="resolved($event)" siteKey="{{RECAPTCHA_SITE_KEY}}"></re-captcha>
        </div>
      </div>
    </div>
  </div>
</div>
