import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AppState } from '../../../reducers';
import { ActorModel } from '../../../actor/models/actor.model';
import { getBoards } from '../../../auth/selectors/auth.selector';
import {BaseUserService} from "../base-user/base-user.service";
import {AppPermissions} from "../../../user/enum/app-permissions.enum";

@Injectable({
  providedIn: 'root',
})
export class BoardAuthService {
  constructor(
    private store: Store<AppState>,
    private baseUserService: BaseUserService,
  ) {}

  async canUserEditActorByBoard(actor: ActorModel) {
    const loggedUser = await this.baseUserService.loggedUser();
    if(loggedUser.rolesJson?.includes(AppPermissions.SUPERADMIN)) return true;
    if (!actor.boardId && !actor.boardDelegatedId) return false;
    const boards = await this.store.select(getBoards).pipe(take(1)).toPromise();
    return boards.includes(actor.boardId || -1) || boards.includes(actor.boardDelegatedId || -1);
  }
}
