export enum ChatMessageActionTypeLabel {
    new_text = 'Nuovo messaggio',
    new_journal = 'Nuovo post diario',
    new_survey = 'Nuovo sopralluogo',
    share_actor_document = 'Condividi documento privato',
    new_construction_document = 'Nuovo documento',
    share_equipment = 'Condividi mezzo',
    new_equipment = 'Nuovo mezzo',
    new_photo_gallery = 'Nuovo fascicolo fotografico',
    new_activity = 'Nuova attività'
}

export enum ChatMessageType {
  new_text = 'Messaggio',
  new_journal = 'Diario',
  new_photo_gallery = 'Fascicolo fotografico',
  new_survey = 'Sopralluogo',
  new_construction_document = 'Documento di cantiere',
  new_equipment = 'Mezzo di cantiere',
  share_equipment = 'Mezzo privato',
  share_actor_document = 'Documento privato',
  new_activity = 'Nuova attività'
}

export enum ChatMessageActionType {
  new_text = 'new_text',
  new_journal = 'new_journal',
  new_photo_gallery = 'new_photo_gallery',
  new_survey = 'new_survey',
  new_construction_document = 'new_construction_document',
  new_equipment = 'new_equipment',
  share_equipment = 'share_equipment',
  share_actor_document = 'share_actor_document',
  new_activity = 'new_activity'
}

export function LabelToType (label: ChatMessageActionTypeLabel | string) {
  switch (label) {
    case ChatMessageActionTypeLabel.new_text:
      return ChatMessageActionType.new_text;
    case ChatMessageActionTypeLabel.new_journal:
      return ChatMessageActionType.new_journal;
    case ChatMessageActionTypeLabel.new_survey:
      return ChatMessageActionType.new_survey;
    case ChatMessageActionTypeLabel.new_construction_document:
      return ChatMessageActionType.new_construction_document;
    case ChatMessageActionTypeLabel.new_equipment:
      return ChatMessageActionType.new_equipment;
    case ChatMessageActionTypeLabel.share_equipment:
      return ChatMessageActionType.share_equipment;
    case ChatMessageActionTypeLabel.share_actor_document:
      return ChatMessageActionType.share_actor_document;
    case ChatMessageActionTypeLabel.new_photo_gallery:
      return ChatMessageActionType.new_photo_gallery;
    case ChatMessageActionTypeLabel.new_activity:
      return ChatMessageActionType.new_activity;
  }
}
