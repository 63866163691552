<mat-dialog-content>
  Questo sito installa cookie tecnici, analitici e di profilazione di terza parte. <br />
  Cliccando Ok autorizzi l'utilizzo di tali cookie. <br />

  <div class="selection-div">
    <mat-slide-toggle color="primary" [checked]="selectedTechnicalCookie" [disabled]="true"> Cookie tecnici </mat-slide-toggle> <br />
    <mat-slide-toggle color="primary" [(ngModel)]="selectedAnalyticCookie" autofocus>Cookie analitici</mat-slide-toggle>
  </div>

  <div class="cookie-policy">
    Per maggiori informazioni leggere
    <a class="navigation-link" href="https://static.check-cantiere.it/docs/cookie-policy.pdf">l'informativa cookie</a>
  </div>

  <div class="check-flex-row check-space-evenly check-items-center">
    <button mat-flat-button color="primary" (click)="onOkClicked()">Ok</button>
    <button mat-flat-button (click)="onRejectClicked()">Rifiuta</button>
  </div>
</mat-dialog-content>
