import { Component, Input } from '@angular/core';
import { MessageModel } from '../../models/message.model';

@Component({
  selector: 'app-alert-messages-box',
  templateUrl: './alert-messages-box.component.html',
  styleUrls: ['./alert-messages-box.component.scss'],
})
export class AlertMessagesBoxComponent {
  @Input() messages: MessageModel[] = [];
}
