import {Injectable} from '@angular/core';
import {PlatformEnum} from "../../enums/platform.enum";
import {Capacitor} from "@capacitor/core";
import {DeviceDto} from "../../../auth/models/login.model";


@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor() { }

  getCapacitorPlatform() {
    return Capacitor.getPlatform();
  }

  getPlatformEnum() : PlatformEnum {
    const platform = this.getCapacitorPlatform();
    return PlatformEnum[platform];
  }

  getFirebasePlatformEnum() {
    const platform = this.getPlatformEnum();
    return platform == PlatformEnum.android ? PlatformEnum.and : platform;
  }

  isMobileDevice() {
    const platform = PlatformEnum[Capacitor.getPlatform()];
    return (platform == PlatformEnum.android || platform == PlatformEnum.ios);
  }

  isAndroidDevice() {
    return Capacitor.getPlatform() == PlatformEnum.android
  }

  getOrCreateDeviceDto() : DeviceDto {
    let deviceDto = this.getDeviceDtoFromLS();
    if(!deviceDto || !deviceDto.identifier) {
      deviceDto = new DeviceDto();
    }
    return deviceDto;
  }

  saveDeviceInfoToLS() {
    // salvo nel local-storage le info del dispositivo così login e refresh-tokens possono accedere ai dati in modo sincrono
    const deviceDto = this.getOrCreateDeviceDto();
    localStorage.setItem('device', JSON.stringify(deviceDto));
  }

  getDeviceDtoFromLS() : DeviceDto {
    const deviceDtoStr = localStorage.getItem('device');
    if(deviceDtoStr) {
      return JSON.parse(deviceDtoStr);
    }
    return null;
  }
}
