import {Injectable} from '@angular/core';
import {MessageModel} from "../../shared/models/message.model";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {getMe, getToken} from "../../auth/selectors/auth.selector";
import {map, take} from "rxjs/operators";
import {BaseUserService} from "../../shared/services/base-user/base-user.service";

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {

  constructor(
    private http: HttpClient,
    private baseUserService: BaseUserService
  ) {
  }

  async checkUserArchiveDownloadRequest(plannedOperationId: string) {
    const loggedUser = await this.baseUserService.loggedUser();
    if(!loggedUser) return false;
    return await this.http.get<boolean>(`${environment.BASE_PATH}/planned-operation/check-user-archive-download-request/${plannedOperationId}`).toPromise();
  }

  downloadArchive(plannedOperationId: string) {
    return this.http.get(`${environment.BASE_PATH}/planned-operation/download-archive/${plannedOperationId}`, {
      observe: "response",
      responseType: "blob",
    });
  }
}
