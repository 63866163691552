import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../reducers';
import { acceptTerms, logout } from '../../actions/auth.actions';
import { getMe } from '../../selectors/auth.selector';
import { take } from 'rxjs/operators';
import { AppPermissions } from '../../../user/enum/app-permissions.enum';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements OnInit {
  url: string = environment.STATIC_PATH + '/docs/';
  htmlData: any;
  htmlString: string = '';
  termsSeen: boolean = false;

  constructor(private store: Store<AppState>) {}

  async ngOnInit(): Promise<void> {
    await this.setupTermsByRole();
  }

  updateTermsSeen() {
    this.termsSeen = !this.termsSeen;
  }

  async setupTermsByRole() {
    const user = await this.store.select(getMe).pipe(take(1)).toPromise();
    if (user?.rolesJson?.includes(AppPermissions.ROLE_BOARD_ADMIN) || user?.rolesJson?.includes(AppPermissions.SUPPORT) || user?.rolesJson?.includes(AppPermissions.GUEST)) {
      this.url += 'TERMINI-CASSE.html';
    } else {
      this.url += 'TERMINI-ATTORI.html';
    }
  }

  acceptTerms() {
    if (this.termsSeen) {
      this.store.dispatch(acceptTerms());
    }
  }

  notAcceptTerms() {
    this.store.dispatch(logout());
  }
}
