import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {AppState} from '../../../reducers';
import {LoginDto, LoginResponseDto, RefreshTokensDto} from '../../models/login.model';
import {logout, storeTokens} from '../../actions/auth.actions';
import {throwError} from 'rxjs';
import {DeviceService} from "../../../shared/services/device/device.service";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private BASE_ROUTE = '/auth/';
  private LOGIN = this.BASE_ROUTE + 'login';
  private LOGOUT = this.BASE_ROUTE + 'logout';
  private REFRESH_TOKENS = this.BASE_ROUTE + 'refresh-tokens';

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private deviceService: DeviceService,
  ) {}

  login(username: string, password: string) {
    const url = `${environment.BASE_PATH}${this.LOGIN}`;
    const loginDto = { username: username, password: password } as LoginDto;

    const deviceDto = this.deviceService.getDeviceDtoFromLS();
    if(deviceDto) {
      loginDto.device = deviceDto;
    }
    return this.http.post<LoginResponseDto>(`${url}`, loginDto);
  }

  refreshTokens() {
    console.log('parte richiesta refresh'); // Il problema è che le richieste di refresh dopo la prima partono prima ancora che la prima abbia successo
    localStorage.setItem('isRefreshing', JSON.stringify(true));

    const url = `${environment.BASE_PATH}${this.REFRESH_TOKENS}`;
    let refreshToken = AuthService.getRefreshTokenFromLS() as RefreshTokensDto;
    const user = AuthService.getUserFromLS();

    const deviceDto = this.deviceService.getDeviceDtoFromLS();

    return this.http.post<LoginResponseDto>(url, { user, refreshToken, device: deviceDto }).pipe(
      tap((response: LoginResponseDto) => {
        console.log('token refresh fatto');
        localStorage.setItem('isRefreshing', JSON.stringify(false));
        return this.store.dispatch(storeTokens(response));
      }),
      catchError(e => {
        localStorage.setItem('isRefreshing', JSON.stringify(false));
        this.store.dispatch(logout());
        return throwError(e);
      })
    );
  }

  logout() {
    const refreshToken = AuthService.getRefreshTokenFromLS();
    const url = `${environment.BASE_PATH}${this.LOGOUT}`;
    const device = this.deviceService.getDeviceDtoFromLS();
    return this.http.post<boolean>(url, { refreshToken: refreshToken, device: device });
  }


  private static getRefreshTokenFromLS() {
    return JSON.parse(localStorage.getItem('refresh') || '{}');
  }

  private static getUserFromLS() {
    return JSON.parse(localStorage.getItem('user') || '{}');
  }

  refreshAuthData () {
    const device = this.deviceService.getDeviceDtoFromLS();
    return this.http.post<LoginResponseDto>(`${environment.BASE_PATH}/auth/refresh-auth-data`, {device: device});
  }

}
