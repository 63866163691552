export const environment = {
  firebase: {
    projectId: 'check-cape-1479482151130',
    appId: '1:582915333464:web:5afd537338400d6edcd92d',
    storageBucket: 'check-cape-1479482151130.appspot.com',
    locationId: 'europe-west3',
    apiKey: 'AIzaSyDkkXRlqCyJkWq_EOeub3udPqeCXmMDvyg',
    authDomain: 'check-cape-1479482151130.firebaseapp.com',
    messagingSenderId: '582915333464',
    measurementId: 'G-V42QZTLKRT',
  },
  production: true,
  BASE_PATH: 'https://api.check-cantiere.it/api/v1',
  DATE_FORMAT: 'dd-MM-yyyy',
  DATE_TIME_FORMAT: 'dd-MM-yyyy HH:mm',
  MAPBOX_KEY: 'pk.eyJ1Ijoib3ZlcmZsbyIsImEiOiJja2t6YThmcTYwbmttMnBtb2l5OTBtMmFnIn0.D8mBBhzTeQZNge0SmTHaGw',
  MAPBOX_KEY_IOS: 'pk.eyJ1Ijoib3ZlcmZsbyIsImEiOiJjbG5kYXJ1YXAwM2UzMmtuempyc2w4eDM1In0.pCm4Qq0oUcAV3nJYbvf_fw',
  IFRAME_PATH: 'https://check-cantiere.it/external-page/conversation/admin-block/',
  DOCUMENT_DOWNLOAD_BASE_PATH: 'https://check-cantiere.it/documentfile/download/',
  GOOGLE_TAG_ID: 'GTM-N6V7PP5',
  STATIC_PATH: 'https://static.check-cantiere.it',
  PHP_PATH: 'https://check-cantiere.it/',

  documentUploadMaxSize: '33554432', // Bytes
  documentAllowedTypesInput: 'application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/csv,application/epub+zip,image/gif,image/jpeg,application/vnd.oasis.opendocument.presentation,application/vnd.oasis.opendocument.spreadsheet,application/vnd.oasis.opendocument.text,image/png,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/x-rar-compressed,application/rtf,image/svg+xml,image/tiff,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,application/pkcs7-mime,application/x-pkcs7-mime,image/jfif',
  documentUploadAllowedTypesDescriptions: ['pdf', 'office/openoffice', 'csv', 'epub', 'gif', 'jpeg', 'tiff', 'rtf', 'zip', 'p7m', 'jfif'],
  documentUploadAllowedTypes: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'text/csv', 'application/epub+zip', 'image/gif', 'image/jpeg', 'application/vnd.oasis.opendocument.presentation', 'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.oasis.opendocument.text', 'image/png', 'application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/x-rar-compressed', 'application/rtf', 'image/svg+xml', 'image/tiff', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/zip', 'application/pkcs7-mime', 'application/x-pkcs7-mime', 'jfif'],
  cookieToken: 'check_token',
  RECAPTCHA_SITE_KEY: '6LcrSI4dAAAAAH2EwpkxzJpJSB32a1exwDDqE9kb',
  MAX_FILES: 100,
  PWA_ENABLED: true,
  USER_HOME_ENABLED: true,
  FRONTEND_PATH: 'https://check-cantiere.it',
  env: 'prod',
  devHash: '',

  GOOGLE_PLAY_PATH: 'https://play.google.com/store/apps/details?id=it.cassaedilebrescia.check',
  APP_STORE_PATH: 'https://apps.apple.com/it/app/check-portale-del-cantiere/id6448638153',

  HANDBOOK_PUBLIC_FILENAME: 'Manuale-Check.pdf',
  COOKIE_POLICY_PUBLIC_FILENAME: 'cookie-policy.pdf',
  BOARD_PRIVACY_PUBLIC_URL: 'INFORMATIVA-PRIVACY-CASSE.html',
  ACTOR_PRIVACY_PUBLIC_URL: 'INFORMATIVA-PRIVACY-ATTORI.html',

  checkCourseTab: true,
};
