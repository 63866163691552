import {Component, Inject, OnInit} from '@angular/core';
import { environment } from 'src/environments/environment';
import {PlatformEnum} from "../../enums/platform.enum";
import {Capacitor} from "@capacitor/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-mobile-store-dialog',
  templateUrl: './mobile-store-dialog.component.html',
  styleUrls: ['./mobile-store-dialog.component.scss'],
})
export class MobileStoreDialogComponent  implements OnInit {

  protected readonly window = window;
  protected readonly PlatformEnum = PlatformEnum;
  environment = environment;
  platform: PlatformEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      dialogTitle: string,
      description: string,
      storeBtnLabel: string,
      disableClose: boolean
    },
    private dialogRef: MatDialogRef<MobileStoreDialogComponent>
  ) { }

  ngOnInit() {
    this.platform = Capacitor.getPlatform() as PlatformEnum;
    this.dialogRef.disableClose = this.data.disableClose;
  }
}
