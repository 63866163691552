<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<div class="login-container check-flex-col check-justify-center check-items-center">
  <div class="white-box-container">
    <ng-container>
      <div class="login-header check-flex-col check-space-evenly check-items-center">
        <img src="../../../../assets/img/icons/logos/logo.png"/>
        <h1 style="margin-top: 20px"><a routerLink="/{{CommonRoutes.LOGIN}}">Il Portale del Cantiere</a></h1>
      </div>
      <div>
        <app-alert-messages-box [messages]="messages"></app-alert-messages-box>
      </div>
      <br/>
      <div class="check-flex-row">
        <app-request-password-link></app-request-password-link>
      </div>
      <br/>
      <div class="border center">
        <a href="https://www.check-cantiere.it" target="_blank">Sponsor | Per saperne di più</a>
      </div>
    </ng-container>
  </div>
</div>
