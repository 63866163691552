import { Component, OnInit } from '@angular/core';
import {UserService} from "../../../user/services/user/user.service";
import {ActivatedRoute} from "@angular/router";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {MessageModel} from "../../../shared/models/message.model";
import {CommonRoutes} from "../../../shared/enums/routes.enum";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class ResetPasswordComponent implements OnInit {


  confirmationToken: string;
  hideForm = false;

  PASSWORD_REGEX = "^(?=.*\\d)[a-zA-Z0-9]{7,}$";

  CommonRoutes = CommonRoutes;
  messages: MessageModel[] = [];

  form = this.fb.group({
    password: new FormControl('',[Validators.pattern(this.PASSWORD_REGEX)]),
    repeatPassword: new FormControl('', [Validators.pattern(this.PASSWORD_REGEX)]),
  });


  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
  ) { }

  async ngOnInit(): Promise<void> {
    this.confirmationToken = this.activatedRoute.snapshot.paramMap.get('confirmationToken');
    const messageModel = await this.userService.checkUserForResetPassword(this.confirmationToken);
    if(messageModel.error) {
      this.hideForm = true;
    }
    this.messages.push(messageModel);
  }

  async reset() {
    this.messages = [];
    this.form.markAllAsTouched();

    if(this.form.valid) {
      const password = this.form.get('password').value;
      if(password && password != this.form.get('repeatPassword').value) {
        this.messages.push(new MessageModel(`Le password non sono uguali`, true));
        return;
      }

      const messageModel = await this.userService.resetPassword(password, this.confirmationToken);
      this.hideForm = true;
      this.messages.push(messageModel);
    }
  }
  goBack() {
    window.history.back();
  }
}
