import {Pipe, PipeTransform} from '@angular/core';
import {SelectOptionModel} from "../../models/select-option.model";

@Pipe({
  name: 'addSelectedOption'
})
export class AddSelectedOptionPipe implements PipeTransform {

  transform(options: SelectOptionModel[], selectedOption?: SelectOptionModel) {
    const hasToAddSelectedOption = (selectedOption?.value) && !options.find(option => option.value === selectedOption.value);

    if (hasToAddSelectedOption) {
      options = [...options, selectedOption];

      if (options.length) {
        options.sort((a, b) => {
          const keyA = a.key.toUpperCase();
          const keyB = b.key.toUpperCase();

          return (keyA < keyB) ? -1 : ((keyA < keyB) ? 1 : 0);
        })
      }
    }

    return options;
  }

}
