<div class="login-container check-flex-col check-justify-center check-items-center">
  <div class="white-box-container">
    <div class="login-header check-flex-col check-space-evenly check-items-center">
      <img src="../../../../assets/img/icons/logos/logo.png"/>
      <h1 style="margin-top: 20px"><a routerLink="/{{CommonRoutes.LOGIN}}">Il Portale del Cantiere</a></h1>

      <ng-container *ngIf="!hideForm">
        <h2>Crea il tuo utente</h2>
        <div>Inserisci uno username ed una password</div>
      </ng-container>

      <ng-container *ngIf="messages.length > 0">
        <app-alert-messages-box class="w-full" [messages]="messages"></app-alert-messages-box>
      </ng-container>
    </div>
    <form *ngIf="!hideForm" [formGroup]="form" class="login-form check-flex-col">
      <label>Username (email non pec)</label>
      <mat-form-field appearance="outline">
        <input autocomplete="off" formControlName="username" matInput type="text"/>
        <mat-error *ngIf="form.get('username').hasError('required')">Campo obbligatorio</mat-error>
        <mat-error *ngIf="form.get('username').hasError('email')">Deve essere una mail valida</mat-error>
      </mat-form-field>

      <label>Password</label>
      <mat-form-field appearance="outline">
        <input autocomplete="off" formControlName="password" matInput type="password"/>
        <mat-error *ngIf="form.get('password').hasError('pattern')">Password non valida, sono necessari almeno 6
          caratteri e una cifra
        </mat-error>
        <mat-error *ngIf="form.get('password').hasError('required')">Campo obbligatorio</mat-error>
      </mat-form-field>
      <br/>
      <div class="w-full check-flex-row check-space-between check-items-center">
        <button mat-flat-button (click)="goBack()">Annulla</button>
        <button color="primary" mat-flat-button (click)="change()" [disabled]="hideForm || !form.valid">
          Conferma
        </button>
      </div>
      <br/>
      <div class="border center">
        <a href="https://www.check-cantiere.it" target="_blank">Sponsor | Per saperne di più</a>
      </div>
    </form>
  </div>
</div>
