<div class="container">
  <div class="fill-available-space">
    <div class="white-box-container fill-available-space check-flex-col">
      <iframe id="iframe-privacy" [src]="url | safe" width="100%" height="90%"></iframe>

      <div style="margin-top: 20px">
        <mat-checkbox [checked]="termsSeen" (change)="updateTermsSeen()" color="primary"> Ho preso visione dei termini </mat-checkbox>
      </div>
      <div class="check-flex-row check-justify-start check-items-center" style="margin-top: 20px">
        <div>
          <button mat-dialog-close mat-flat-button color="warn" (click)="notAcceptTerms()" data-toggle="tooltip" title="Non accettando non potrai utilizzare Check">Non accetto</button>
        </div>
        <div class="w-full check-flex-row check-justify-end check-items-center">
          <button mat-flat-button color="primary" (click)="acceptTerms()" [disabled]="!termsSeen">Accetto i termini</button>
        </div>
      </div>
    </div>
  </div>
</div>
