import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { logoutSuccess } from '../auth/actions/auth.actions';

export interface AppState {}

export const reducers: ActionReducerMap<AppState> = {};

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [clearState] : [clearState];

export function clearAuthStorageData() {
  localStorage.removeItem('refresh');
  localStorage.removeItem('user');
  localStorage.removeItem('auth');
}

export function clearState(reducer: any) {
  return function (state: any, action: any) {
    if (action.type === logoutSuccess.type) {
      clearAuthStorageData();
      state = undefined;
      window.location.reload();
    }

    return reducer(state, action);
  };
}
