
import {registerPlugin} from "@capacitor/core";
import {AndroidPickMediaType} from "../enums/android-pick-media-type.enum";

export type AndroidPickMediaOptions = {
  type: AndroidPickMediaType,
  multiple: boolean
}

export type AndroidPickFileOptions = {
  multiple: boolean
}

export type AndroidPickResult = { result: string }
export type AndroidPickedFile = { uri: string, fileName: string, mimeType: string }

export interface AndroidFilePickerPlugin {
  pickMedia(options: AndroidPickMediaOptions): Promise<AndroidPickResult>;
  pickFile(options: AndroidPickFileOptions): Promise<AndroidPickResult>;
}

const AndroidFilePicker = registerPlugin<AndroidFilePickerPlugin>("AndroidFilePicker");

export default AndroidFilePicker;
