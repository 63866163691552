import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ArchiveService} from "../../services/archive.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../reducers";
import {CheckRoutes} from "../../../shared/enums/routes.enum";

@Injectable({
  providedIn: 'root'
})
export class CanActivateDownloadArchiveGuard implements CanActivate {
  constructor(
    private router: Router,
    private archiveService: ArchiveService,
    private store: Store<AppState>,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve, reject) => {
      const plannedOperationId = route.paramMap.get('plannedOperationId');
      this.archiveService.checkUserArchiveDownloadRequest(plannedOperationId).then((hasUserArchiveDownloadRequest) => {
        if(hasUserArchiveDownloadRequest == true) {
          resolve(true);
        }
        else {
          this.router.navigateByUrl(`/${CheckRoutes.USER}`);
          resolve(false);
        }
      })
    })
  }

}
