import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './effects/auth.effect';
import { authFeatureKey, reducer } from './reducers/auth.reducers';
import { LoginComponent } from './components/login/login.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { CookieModule } from 'ngx-cookie';
import { PrivacyComponent } from './components/privacy/privacy.component';
import {RequestPasswordComponent} from "./components/request-password/request-password.component";
import { ChangeUsernamePasswordComponent } from './components/change-username-password/change-username-password.component';
import { UserConfirmComponent } from './components/user-confirm/user-confirm.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RequestPasswordLinkComponent } from './components/request-password-link/request-password-link.component';
import {RecaptchaModule} from "ng-recaptcha";
import {UserActivationComponent} from "./components/user-activation/user-activation.component";

@NgModule({
  declarations: [
    LoginComponent,
    CookiePolicyComponent,
    PrivacyComponent,
    RequestPasswordComponent,
    ChangeUsernamePasswordComponent,
    UserConfirmComponent,
    ResetPasswordComponent,
    RequestPasswordLinkComponent,
    UserActivationComponent,
  ],
  imports: [
    RecaptchaModule,
    SharedModule,
    MatSlideToggleModule,
    FormsModule,
    EffectsModule.forFeature([AuthEffects]),
    StoreModule.forFeature(authFeatureKey, reducer),
    CookieModule.forRoot(),
    // ActorModule,
  ],
  exports: [MatSlideToggleModule],
})
export class AuthModule {}
