import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {ChatGroupModel} from '../../models/chat-group.model';
import {CrudService} from "../../../shared/services/crud/crud.service";
import {RequestQueryBuilder} from "@nestjsx/crud-request";
import {UserModel} from "../../../user/models/user.model";
import {ContentMessageModel, SharableEntity} from "../../models/shared-entity.model";
import {CcoType} from "../../../cco/enum/cco-type.enum";
import {BaseUserService, isUserBaseUserOfActor} from "../../../shared/services/base-user/base-user.service";
import {AppPermissions} from "../../../user/enum/app-permissions.enum";
import {UtilsService} from "../../../shared/services/utils/utils.service";
import {NotificationEntityData, NotificationType} from "../../../shared/enums/notification.type.enum";
import {CheckRoutes} from "../../../shared/enums/routes.enum";
import {
  DataNotification, FirebaseNotificationService,
  MessageNotification
} from "../../../shared/services/firebase-notification/firebase-notification.service";

@Injectable({
  providedIn: 'root'
})
export class ChatGroupService {

  constructor(
    private http: HttpClient,
    private crudService: CrudService<ChatGroupModel>,
    private utilsService: UtilsService,
    private baseUserService: BaseUserService,
    private firebaseNotificationService: FirebaseNotificationService,
  ) { }

  getChatInfoObs () {
    return this.http.get<{userChatUID: string, chatToken: string}>(`${environment.BASE_PATH}/chat-group/user/info`);
  }

  async getChatInfo(): Promise<{userChatUID: string, chatToken: string}> {
    return this.http.get<{userChatUID: string, chatToken: string}>(`${environment.BASE_PATH}/chat-group/user/info`).toPromise();
  }

  async getUsersUIDs(userIds: number[]): Promise<{userId: number, userChatUID: string}[]> {
    return (await this.http.post<{userChatUIDs: {userId: number, userChatUID: string}[]}>(`${environment.BASE_PATH}/chat-group/get-user-chat-uids`, {userIds}).toPromise())?.userChatUIDs;
  }

  async getMany(query: string = '') {
    return await this.crudService.getMany(`chat-group`, query);
  }

  async getOne(id: number, query: string = '') {
    return await this.crudService.getOne(id, `chat-group`, query);
  }
  async createChatGroup(chatGroup: ChatGroupModel) {
    return this.http.post<ChatGroupModel>(`${environment.BASE_PATH}/chat-group`, chatGroup).toPromise();
  }

  async replaceOne(chatGroup: ChatGroupModel) {
    return await this.crudService.replaceOne(`chat-group`, chatGroup);
  }

  async deleteOne(chatGroupId: number) {
    return await this.crudService.deleteOne('chat-group', chatGroupId);
  }

  async getChatGroupDataByUid(uid: string) {
    const qb = RequestQueryBuilder.create({
      search: {"uid" : uid},
      join: [{field: 'userInChatGroups'}, {field: 'userInChatGroups.user'}]
    })
    const result : any = await this.getMany(qb.query());
    if(result.data?.length > 0) {
      return result.data[0];
    }
    return null;
  }

  async getChatGroupsByConstructionId(constructionId: number) {
    const qb = RequestQueryBuilder.create({
      search: {"constructionId" : constructionId},
    });
    const result: any = await this.getMany(qb.query());

    return result.data;
  }

  isLoggedActorTheOnlyActorInChatGroup(loggedUser: UserModel, chatGroupWithData: ChatGroupModel) {
    const loggedActorId = loggedUser.baseActorId;
    const usersInChatGroup = chatGroupWithData.userInChatGroups.map(userInChatGroup => userInChatGroup.user);
    const otherActors = usersInChatGroup.filter(user => user.baseActorId != loggedActorId);
    return otherActors.length == 0;
  }

  isUserAllowedToAccessChatGroup(user: UserModel, constructionId: number) {
    if(user.actor) {
      if(isUserBaseUserOfActor(user, user.actor)) { // l'utente principale dell'actor vede tutti i cantieri
        return true;
      }
    }

    if (user.rolesJson?.includes(AppPermissions.CAN_SEE_ALL_CONSTRUCTIONS)){
      return true;
    }

    const constructions = typeof user.constructions === "string" ? this.utilsService.fromStringToNumberArray(user.constructions) : user.constructions;
    return constructions?.includes(constructionId);
  }

  canContentBeRemovedFromChat(content: ContentMessageModel) {
    return !(content.entityName == SharableEntity.CCO &&
      (content.entityData.type == CcoType.JOURNAL || content.entityData.type == CcoType.SURVEY)
    );
  }

  async sendChatGroupNotification(type: NotificationType, entity: ChatGroupModel, usersInGroup: UserModel[]) {
    const dataNotification : DataNotification = {
      type: type,
      entityId: entity.id,
      entityName: NotificationEntityData.CHAT,
      entityData: entity,
    }

    const messageNotification : MessageNotification = {
      link : this.setNotificationRedirectLinkByType(type, entity),
      queryParams : this.setNotificationParamsByType(type, entity),
      message: await this.setNotificationMessageByType(type, entity),
      recipientUsers: await this.setNotificationRecipientUsersByType(type, usersInGroup)
    }

    if(messageNotification.recipientUsers?.length > 0) {
      await this.firebaseNotificationService.sendFirebaseNotification(dataNotification, messageNotification)
        .catch(e => {console.log(e)});
    }
  }

  private async setNotificationMessageByType(notificationType: NotificationType, entity: ChatGroupModel) {
    const loggedUser = await this.baseUserService.loggedUser();
    switch (notificationType) {
      case NotificationType.CHAT_CREATED:
        return `L'utente ${loggedUser.username} ti ha aggiunto nella chat ${entity.groupName}`;
    }
    return '';
  }

  private setNotificationParamsByType(notificationType: NotificationType, entity: ChatGroupModel) {
    switch (notificationType) {
      case NotificationType.CHAT_CREATED:
        return JSON.stringify({group: entity.uid});
    }
    return '';
  }

  private setNotificationRedirectLinkByType(notificationType: NotificationType, entity: ChatGroupModel) {
    switch (notificationType) {
      case NotificationType.CHAT_CREATED:
        return `${CheckRoutes.USER}/${CheckRoutes.CONSTRUCTION}/${CheckRoutes.OFFICE}/${entity.constructionId}`;
    }
    return '';
  }

  private async setNotificationRecipientUsersByType(notificationType: NotificationType, usersInGroup: UserModel[]) {
    switch (notificationType) {
      case NotificationType.CHAT_CREATED:
        const loggedUser = await this.baseUserService.loggedUser();
        const recipients = usersInGroup.filter(u => u.id != loggedUser.id);
        if(recipients.length > 0) {
          return recipients.map(u => u.chatUID);
        }
    }
    return [];
  }
}
