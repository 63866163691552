<div class="login-container check-flex-col check-justify-center check-items-center">
  <div class="white-box-container">
    <div class="login-header check-flex-col check-space-evenly check-items-center">
      <img src="../../../../assets/img/icons/logos/logo.png" />
      <h1 style="margin-top: 20px">Scarica archivio</h1>
      <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    </div>
    <form class="login-form">
      <div class="center">
        <div style="max-width: 350px; margin: auto">
          {{loggedUser?.email}}, clicca il tasto seguente per scaricare l'archivio del cantiere <b>{{constructionName}}</b>
        </div>
        <br />
        <button color="primary" mat-flat-button (click)="downloadArchive()">Scarica archivio</button>
      </div>
      <br />
      <div class="border center">
        <a routerLink="`/{{CheckRoutes.USER}}`">Vai alla dashboard</a>
      </div>
    </form>
  </div>
</div>
