import {Injectable} from '@angular/core';
import {FilterWhereClause} from "../../models/filter.model";
import {CrudOperator} from "../../enums/nestjs.enum";
import {QueryFilter, RequestQueryBuilder} from "@nestjsx/crud-request";

@Injectable({
  providedIn: 'root'
})
export class FilterDialogService {

  constructor() {
  }

  async buildQueryFromFilter(filterWhereClauses: FilterWhereClause[]) {
    const qb = RequestQueryBuilder.create();
    for(const filter of filterWhereClauses) {
      const queries: QueryFilter[] = [];
      const baseQuery: QueryFilter = {
        field: filter.field,
        operator: filter.operator
      };
      if(filter.value) {
        Object.assign(baseQuery, { value: baseQuery.operator != CrudOperator.IN ? `"${filter.value}"` : filter.value });
      }
      queries.push(baseQuery);

      if(filter.additionalQueries) {
        for(const additionalQuery of filter.additionalQueries.queries) {
          const query: QueryFilter = {
            field: additionalQuery.field,
            operator: additionalQuery.operator,
          };
          if(additionalQuery.value) {
            Object.assign(query, { value: `"${additionalQuery.value}"` });
          }
          queries.push(query);
        }
        if(filter.additionalQueries.operator == CrudOperator.OR) {
          qb.setOr(queries);
          continue;
        }
      }
      qb.setFilter(queries);
    }
    console.log('Filtri: ', qb.query() ? qb.query(false) : 'nessun filtro');
    return qb;
  }
}
