import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs/operators';
import { AppState } from '../../../reducers';
import {canNavigate, getRoles} from '../../selectors/auth.selector';
import {isSupportUser} from "../../../shared/services/base-user/base-user.service";
import {CheckRoutes} from "../../../shared/enums/routes.enum";

@Injectable()
export class CanActivateGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.store.select(canNavigate).pipe(
      take(1),
      map(can => {
        if (can) {
          this.store.select(getRoles).subscribe(roles => {
            if(isSupportUser(roles)) {
              this.router.navigateByUrl('/home');
            } else {
              this.router.navigateByUrl(`/${CheckRoutes.USER}`)
            }
          });
        }
        return true;
      })
    );
  }
}
