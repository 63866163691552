import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseModel } from '../../../shared/models/response.model';
import { environment } from '../../../../environments/environment';
import { PlannedOperationModel } from '../../models/planned-operation.model';

@Injectable({
  providedIn: 'root'
})
export class PlannedOperationService {

  constructor(private http: HttpClient) { }

  async createOne(plannedOperation: PlannedOperationModel): Promise<ResponseModel> {
    const data = {
      plannedOperation: plannedOperation,
    };
    return await this.http.post<ResponseModel>(`${environment.BASE_PATH}/planned-operation/`, data).toPromise();
  }

  async getOne(plannedOperationId: string) {
    return await this.http.get<PlannedOperationModel>(`${environment.BASE_PATH}/planned-operation/${plannedOperationId}`).toPromise();
  }
}
