import {AfterViewInit, Component, HostListener, NgZone, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {StatusBar, Style} from "@capacitor/status-bar";
import {GoogleAnalyticsService} from "./shared/services/google-analytics/google-analytics.service";
import {DeviceService} from "./shared/services/device/device.service";
import {MobileService} from "./shared/services/mobile/mobile.service";
// import {PushService} from "./shared/services/push/push.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'check';
  checkInnerHeightDebounce: Subject<any> = new Subject();
  innerHeight: number;

  constructor(
    public router: Router,
    public googleAnalyticsService: GoogleAnalyticsService,
    private zone: NgZone,
    private deviceService: DeviceService,
    private mobileService: MobileService,
    // private pushService: PushService,
  ) {

    if(this.googleAnalyticsService.canAddGTM()) {
      this.googleAnalyticsService.pushTagForPageLinks();
    }

    this.checkInnerHeightDebounce.pipe(debounceTime(250)).subscribe(() => {
      this.checkInnerHeight();
    });
  }

  async ngOnInit() {
    if(this.deviceService.isMobileDevice()) {
      await StatusBar.setStyle({style: Style.Light});
    }
    this.deviceService.saveDeviceInfoToLS();
    // await this.pushService.registerNotifications();
    await this.mobileService.openUpdateDialogIfNotUpdated();
    await this.mobileService.openAppDownloadDialogIfNeeded();
  }

  ngAfterViewInit(): void {
    this.checkInnerHeightDebounce.next(null);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkInnerHeightDebounce.next(null);
  }

  checkInnerHeight() {
    let innerHeight = window.innerHeight;
    if (this.innerHeight !== innerHeight) {
      this.innerHeight = innerHeight;
      document.documentElement.style.setProperty('--vh', `${innerHeight}px`);
    }
  }
}
