import { createReducer, on } from '@ngrx/store';
import * as AuthActions from '../actions/auth.actions';
import { TokenModel } from '../models/login.model';
import { UserModel } from '../../user/models/user.model';

export const authFeatureKey = 'auth';
export const refreshFeatureKey = 'refresh';

export interface State {
  token: TokenModel | undefined;
  user: UserModel | undefined;
  refreshToken: TokenModel | undefined;
  error: string | '';
}

export const initialState: State = {
  token: localStorage.getItem(authFeatureKey) ? JSON.parse(localStorage.getItem(authFeatureKey) || '') : undefined,
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : undefined,
  refreshToken: localStorage.getItem(refreshFeatureKey) ? JSON.parse(localStorage.getItem(refreshFeatureKey) || '') : undefined,
  error: '',
};

export const reducer = createReducer(
  initialState,
  on(AuthActions.login, (state, action) => ({ ...state, token: undefined, user: undefined, refreshToken: undefined, error: '' })),
  on(AuthActions.loginSuccess, (state, action) => ({ ...state, token: action.token, refreshToken: action.refreshToken, user: action.user, error: '' })),
  on(AuthActions.logoutSuccess, () => initialState),
  on(AuthActions.loginError, (state, action) => ({ ...state, token: undefined, user: undefined, refreshToken: undefined, error: action.error })),
  on(AuthActions.storeTokens, (state, action) => ({ ...state, token: action.token, refreshToken: action.refreshToken, user: action.user, error: '' })),
  on(AuthActions.acceptTermsSuccess, (state, action) => ({ ...state, user: action })),
  on(AuthActions.refreshAuthDataSuccess, (state, action) => ({ ...state, token: action.token, refreshToken: action.refreshToken, user: action.user, error: '' })),
  on(AuthActions.showError, (state, action) => ({ ...state, error: action.error })),
  on(AuthActions.firebaseSignIn, (state, action) => ({ ...state, user: {...state.user, chatToken: action.chatToken, chatUID: action.userChatUID}}))
);
