import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from '../reducers/auth.reducers';
import * as dayjs from 'dayjs';
import { AppPermissions } from '../../user/enum/app-permissions.enum';
import {environment} from "../../../environments/environment";

export const selectAuthState = createFeatureSelector<fromAuth.State>(fromAuth.authFeatureKey);

export const isLoggedIn = createSelector(selectAuthState, auth => auth.token && dayjs(auth.token.expirationDate).isAfter(dayjs()));

export const canNavigate = createSelector(selectAuthState,
    auth =>
      {
        if(environment.env == 'local') {
          console.log(`auth.token = ${auth.token?.expirationDate}`)
          console.log(`auth.token = ${new Date(auth.token?.expirationDate)}`)
          console.log(`auth.token = ${auth.token?.jwt}`)
        }
        return (
          auth.user?.rolesJson?.includes(AppPermissions.SUPERADMIN) ? true :
            auth.token &&
            auth.refreshToken?.jwt && dayjs(auth.refreshToken.expirationDate).isAfter(dayjs()) &&
            (!!auth.user?.constructionFundTermsAcceptedDate || !!auth.user?.userTermsAcceptedDate)
        );
      }
    );

export const canAcceptTerms = createSelector(selectAuthState, auth => auth.token && dayjs(auth.token.expirationDate).isAfter(dayjs()) && (!auth.user?.constructionFundTermsAcceptedDate || !auth.user?.userTermsAcceptedDate));

export const getToken = createSelector(selectAuthState, auth => (auth.token ? auth.token.jwt : ''));

export const getRoles = createSelector(selectAuthState, auth => (auth.user ? auth.user.rolesJson : []));

export const getAuthState = createSelector(selectAuthState, auth => auth);

export const getBoards = createSelector(selectAuthState, auth => (auth.user ? auth.user.boards : []));

export const getMe = createSelector(selectAuthState, auth => auth.user);

export const getDenomination = createSelector(selectAuthState, auth => auth.user?.denomination);

export const getErrorMessage = createSelector(selectAuthState, auth => auth.error);
