<div class="login-container check-flex-col check-justify-center check-items-center">
  <div class="white-box-container">
    <div class="login-header check-flex-col check-space-evenly check-items-center">
      <img src="../../../../assets/img/icons/logos/logo.png" />
      <h1 style="margin-top: 20px"><a routerLink="/{{CommonRoutes.LOGIN}}">Il Portale del Cantiere</a></h1>
      <ng-container *ngIf="!hideForm">
        <h2>Creazione / Cambio password</h2>
        <div>Inserisci il tuo Username per attivare la procedura di creazione / cambio password</div>
      </ng-container>

      <ng-container *ngIf="messages.length > 0">
        <app-alert-messages-box class="w-full" [messages]="messages"></app-alert-messages-box>
      </ng-container>
    </div>
    <form  *ngIf="!hideForm" [formGroup]="form" class="login-form">
      <label>Username</label>
      <mat-form-field appearance="outline">
        <input autocomplete="off" formControlName="username" matInput type="text" />
      </mat-form-field>
      <br />
      <div class="w-full check-flex-row check-space-between check-items-center">
        <button  mat-flat-button (click)="goBack()">Annulla</button>
        <button  color="primary" mat-flat-button (click)="request()" [disabled]="hideForm || !form.valid">Conferma</button>
      </div>
      <br />
      <div class="border center">
        <a href="https://www.check-cantiere.it" target="_blank">Sponsor | Per saperne di più</a>
      </div>
    </form>
  </div>
</div>
