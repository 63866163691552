import { createAction, props } from '@ngrx/store';
import { ActorModel } from '../models/actor.model';

export const actorFind = createAction('[Actor] Actor to find in database', props<{ fiscalCode: string; index: number }>());
export const actorFound = createAction('[Actor] Actor found', props<{ actorModel: ActorModel }>());
export const actorLoad = createAction('[Actor] Actor data to load in store', props<{ actorModel: ActorModel }>());
export const actorNotFound = createAction('[Actor] Actor not found');

export const actorCreate = createAction('[Actor] Actor to create', props<{ actorModel: ActorModel }>());
export const actorCreatedSuccess = createAction('[Actor] Actor was created with success', props<{ actorModel: ActorModel; errorMessages: string[]; successMessages: string[] }>());
export const actorCreatedError = createAction('[Actor] Actor create error', props<{ errorMessages: string[] }>());

export const actorUpdate = createAction('[Actor] Actor to update', props<{ actorId: string; actorModel: ActorModel }>());
export const actorUpdatedSuccess = createAction('[Actor] Actor was updated with success', props<{ actorModel: ActorModel; errorMessages: string[]; successMessages: string[] }>());
export const actorUpdatedError = createAction('[Actor] Actor update error', props<{ errorMessages: string[] }>());

export const actorClear = createAction('[Actor] Actor clear');

export const loggedActorLoad = createAction('[Actor] Logged Actor to load', props<{ actorId: number }>());
